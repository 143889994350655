import type { RootState } from 'app'

export const getOrganizationsWithChildren = (state: RootState) =>
  state.serviceRequests.organizationsWithChildren

export const isLoading = (state: RootState) => state.serviceRequests.loading

export const getError = (state: RootState) => state.serviceRequests.error

export const getCountries = (state: RootState) =>
  state.serviceRequests.countries

export const getMachineUsers = (state: RootState) =>
  state.serviceRequests.machineUsers

export const getVerifySerialNumber = (state: RootState) =>
  state.serviceRequests.verifySerialNumber

export const getMachineData = (state: RootState) =>
  state.serviceRequests.machineData

export const getServiceRequestPdfFile = (state: RootState) =>
  state.serviceRequests.pdfFile

export const getSelectedDealer = (state: RootState) =>
  state.serviceRequests.selectedDealer
export const getMachineUserIsDealer = (state: RootState) =>
  state.serviceRequests.machineUserIsDealer
export const getIsCreated = (state: RootState) =>
  state.serviceRequests.isCreated
export const getSelectedColumns = (state: RootState) =>
  state.serviceRequests.selectedColumns
export const getDeliverers = (state: RootState) =>
  state.serviceRequests.deliverers
export const getServiceRequests = (state: RootState) =>
  state.serviceRequests.serviceRequestList

export const getMachinesOnlyWithServiceRequests = (state: RootState) =>
  state.serviceRequests.machinesOnlyWithRequests

export const getServiceRequestsStatuses = (state: RootState) =>
  state.serviceRequests.statuses

export const getVehicleTypes = (state: RootState) =>
  state.serviceRequests.vehicleTypes
export const getDescriptions = (state: RootState) =>
  state.serviceRequests.descriptions
export const getPaginationOptions = (state: RootState) =>
  state.serviceRequests.paginationOptions

export const getOrder = (state: RootState) => state.serviceRequests.order

export const areFiltersVisible = (state: RootState) =>
  state.serviceRequests.areFiltersVisible

export const getFilterValues = (state: RootState) =>
  state.serviceRequests.filters

export const getSelectedServiceRequest = (state: RootState) =>
  state.serviceRequests.selectedServiceRequest
export const isServiceRequestAffected = (state: RootState) =>
  state.serviceRequests.isServiceRequestAffected
export const getIsServiceRequestDetails = (state: RootState) =>
  state.serviceRequests.isServiceRequestDetails

export const isRequestReasonModalOpen = (state: RootState) =>
  state.serviceRequests.isRequestReasonModalOpen

export const isRequestCommentModalOpen = (state: RootState) =>
  state.serviceRequests.isRequestCommentModalOpen

export const goToDetails = (state: RootState) =>
  state.serviceRequests.goToDetails
export const isReasonsAdded = (state: RootState) =>
  state.serviceRequests.isReasonsAdded
export const isCommentAdded = (state: RootState) =>
  state.serviceRequests.isCommentAdded

export const getMachineWithUser = (state: RootState) =>
  state.serviceRequests.machine

export const isBadSerialNumberDialogOpen = (state: RootState) =>
  state.serviceRequests.badSerialNumberModalOpen

export const machineUserExists = (state: RootState) =>
  state.serviceRequests.machineUserExists

export const selectedServiceRequestParameters = (state: RootState) => {
  return {
    id: state.serviceRequests.selectedRequestId,
    type: state.serviceRequests.selectedRequestType,
  }
}

export const detailsScrollTo = (state: RootState) =>
  state.serviceRequests.serviceRequestDetailsScrollTo

export const isIncompleteTypeModalOpen = (state: RootState) =>
  state.serviceRequests.isIncompleteTypeModalOpen

export const isRejectTypeModalOpen = (state: RootState) =>
  state.serviceRequests.isRejectTypeModalOpen

export const getOrganizationsToFilter = (state: RootState) =>
  state.serviceRequests.organizationsToFilter

export const isOrganizationsFetching = (state: RootState) =>
  state.serviceRequests.organizationsFetching

export const serialNumbers = (state: RootState) =>
  state.serviceRequests.serialNumbers

export const serialNumbersFetching = (state: RootState) =>
  state.serviceRequests.serialNumbersFetching

export const needFetchDataCount = (state: RootState) =>
  state.serviceRequests.needFetchCount

export const needRefresh = (state: RootState) =>
  state.serviceRequests.needReload

export const isRequestCorrecing = (state: RootState) =>
  state.serviceRequests.isRequestCorrecting

export const isGalleryOpen = (state: RootState) =>
  state.serviceRequests.isGalleryOpen

export const machineExistsInErp = (state: RootState) =>
  state.serviceRequests.machineExistsInErp

export const saleDate = (state: RootState) => state.serviceRequests.saleDate
export const endWarranty = (state: RootState) =>
  state.serviceRequests.endWarranty

export const getMachineUsersAreFetching = (state: RootState) =>
  state.serviceRequests.machineUsersFetchig
