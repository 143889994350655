import { Pagination } from '@material-ui/lab'
import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      '& .Mui-selected': {
        backgroundColor: 'transparent !important',
        color: theme.palette.primary.main + '!important',
      },
    },
  })
)

export interface CustomPaginationProps {
  currentPage: number
  count: number
  setPage: (page: number) => void
}

const CustomPagination: React.FunctionComponent<CustomPaginationProps> = props => {
  const styles = useStyles()
  const { currentPage, count, setPage } = props
  return (
    <Pagination
      size="small"
      color="primary"
      count={count}
      page={currentPage}
      variant="text"
      className={styles.selected}
      onChange={(event, value) => setPage(value - 1)}
    />
  )
}

export default CustomPagination
