import { Loadable } from 'shared/components'
import { ModuleRoute, UserRoles } from 'shared/types'
import PublicIcon from '@material-ui/icons/Public'

const routes: ModuleRoute[] = [
  {
    path: '/organizations',
    exact: true,
    layoutSettings: {
      label: 'navigation.organizations',
      icon: PublicIcon,
    },
    component: Loadable({
      component: () => import('./pages/Organizations'),
    }),
    needRole: [
      UserRoles.D1Administrator,
      UserRoles.D1Employee,
      UserRoles.D2Administrator,
      UserRoles.D2Employee,
      UserRoles.MainAdministrator,
      UserRoles.SamaszEmployee,
      UserRoles.SamaszTrader,
    ],
  },
  {
    path: '/addOrganization',
    exact: true,
    component: Loadable({
      component: () => import('../organizations/pages/AddOrganization'),
    }),
    needRole: [
      UserRoles.D1Administrator,
      UserRoles.D2Administrator,
      UserRoles.MainAdministrator,
      UserRoles.SamaszEmployee,
    ],
  },
  {
    path: '/editOrganization',
    exact: true,
    component: Loadable({
      component: () => import('../organizations/pages/EditOrganization'),
    }),
    needRole: [
      UserRoles.D1Administrator,
      UserRoles.D2Administrator,
      UserRoles.MainAdministrator,
      UserRoles.SamaszEmployee,
    ],
  },
]

export default routes
