import { Overrides } from '@material-ui/core/styles/overrides'
import palette from './palette'
import HKGrotestRegular from '../../fonts/HKGrotesk-Regular.otf'
import HKGroteskMedium from '../../fonts/hk-grotesk.medium.otf'
import HKGroteskBold from '../../fonts/hk-grotesk.bold.otf'
import SikinSans600 from '../../fonts/SinkinSans-600SemiBold.otf'

const sikinSans = {
  fontFamily: 'SinkinSans',
  fontWeight: 600,
  src: `url(${SikinSans600})`,
}

const HKGrotesk = [
  {
    fontFamily: 'HKGrotesk',
    fontWeight: 'normal',
    src: `url(${HKGrotestRegular})`,
  },
  {
    fontFamily: 'HKGrotesk',
    fontWeight: '500',
    src: `url(${HKGroteskMedium})`,
  },
  {
    fontFamily: 'HKGrotesk',
    fontWeight: 'bold',
    src: `url(${HKGroteskBold})`,
  },
]

const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      '@font-face': [sikinSans, ...HKGrotesk],
    },
  },
  MuiTypography: {
    root: {
      fontFamily: 'SinkinSans',
    },
  },
  MuiCircularProgress: {
    colorPrimary: {
      borderColor: palette.primary.main,
    },
  },
  MuiButton: {
    contained: {
      borderRadius: 24,
      fontSize: 12,
    },
    outlined: {
      borderRadius: 24,
      fontSize: 12,
    },
  },
  MuiIconButton: {
    root: {
      color: 'inherit',
    },
  },
  MuiAppBar: {
    colorDefault: {
      backgroundColor: palette.common.white,
      borderBottomWidth: 1,
      borderBottomColor: palette.neutral.light,
      borderBottomStyle: 'solid',
    },
    colorSecondary: {
      color: palette.neutral.contrastText,
      backgroundColor: palette.neutral.light,
    },
  },
  MuiListItemIcon: {
    root: {
      color: 'inherit',
      minWidth: 40,
    },
  },
  MuiInput: {
    root: {
      borderRadius: 24,
      backgroundColor: 'white',
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 24,
      backgroundColor: 'white',
      '& .Mui-disabled': {
        backgroundColor: palette.neutral.light,
        color: 'rgba(0, 0, 0, 0.55)',
      },
    },
  },
  MuiDivider: {
    root: {
      border: 0,
      clear: 'both',
      display: 'block',
      width: '100%',
      backgroundColor: '#d9e6eb',
      height: 1,
    },
  },
  MuiAccordion: {
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
  },
  MuiAccordionSummary: {
    root: {
      paddingLeft: '2%',
    },
  },
  MuiTableHead: {
    root: {
      backgroundColor: '#9BD193',
    },
  },
  MuiTableCell: {
    head: {
      color: 'white',
      fontFamily: 'HKGrotesk',
    },
  },

  MuiMenuItem: {
    root: {
      fontSize: 15,
      fontFamily: 'HKGrotesk',
    },
  },
  //@ts-ignore
  MuiAutocomplete: {
    option: {
      fontFamily: 'HKGrotesk',
    },
  },
  //@ts-ignore
  MuiDataGrid: {
    //@ts-ignore
    root: {
      fontSize: 15,
      fontFamily: 'HKGrotesk',
      '& .MuiDataGrid-iconSeparator': {
        visibility: 'hidden',
      },
      '& .MuiDataGrid-sortIcon': {
        fontSize: 13,
        color: 'white',
      },
      '& .MuiDataGrid-row:nth-of-type(even)': {
        backgroundColor: 'white',
      },
      '& .MuiDataGrid-colCellTitle': {
        fontWeight: 'normal',
      },
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: '#9BD193',
      },
    },
  },
  MuiInputBase: {
    input: {
      fontSize: 15,
      fontFamily: 'HKGrotesk',
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: 15,
      fontFamily: 'HKGrotesk',
      color: '#313131',
    },
  },
  MuiDialog: {
    paperScrollBody: {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
    },
  },
  MuiFormHelperText: {
    contained: {
      marginLeft: '45px',
    },
  },
}

export default overrides
