import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { Api, OrganizationPayload } from 'shared/services'
import { Organization } from 'shared/types/interfaces'
import { OrganizationStatus } from 'shared/types/enums'
import { OrganizationFormValues } from '../components/AddOrganizationForm/AddOrganizationForm.utils'

const api = new Api()

export const getOrganizations = createAsyncThunk(
  'organizations/getOrganizations',
  async () => {
    const response = await api.getOrganizations()
    return response.data
  }
)
export const getCountries = createAsyncThunk(
  'organizations/getCountries',
  async () => {
    const response = await api.getCountries()
    return response.data
  }
)
export const lockOrganization = createAsyncThunk(
  'organizations/lockOrganization',
  async (payload: Organization) => {
    const blocked: OrganizationPayload = {
      name: payload.name,
      acronym: payload.acronym,
      nip: payload.nip,
      country: payload.country.code,
      street: payload.street,
      postalCode: payload.postalCode,
      city: payload.city,
      directionalNumber: payload.directionalNumber,
      phoneNumber: payload.phoneNumber,
      email: payload.email,
      status: OrganizationStatus.Blocked,
      type: payload.type,
      attendantId: payload.attendantId,
      parentOrganizationId: payload.parentOrganizationId,
    }

    await api.editOrganization(blocked, payload.id)
    const response = await api.getOrganizations()
    return response.data
  }
)

export const unlockOrganization = createAsyncThunk(
  'organizations/unlockOrganization',
  async (payload: Organization) => {
    const blocked: OrganizationPayload = {
      name: payload.name,
      acronym: payload.acronym,
      nip: payload.nip,
      country: payload.country.code,
      street: payload.street,
      postalCode: payload.postalCode,
      city: payload.city,
      directionalNumber: payload.directionalNumber,
      phoneNumber: payload.phoneNumber,
      email: payload.email,
      status: OrganizationStatus.Active,
      type: payload.type,
      attendantId: payload.attendantId,
      parentOrganizationId: payload.parentOrganizationId,
    }

    await api.editOrganization(blocked, payload.id)
    const response = await api.getOrganizations()
    return response.data
  }
)

export const deleteOrganization = createAsyncThunk(
  'organizations/delete',
  async (id: number) => {
    await api.deleteOrganization(id)
    return id
  }
)

export const getSamaszTraders = createAsyncThunk(
  'organizations/getTraders',
  async () => {
    const response = await api.getUsersWithRole()
    return response.data
  }
)

export const resetState = createAction('organizations/reset')

export const nameExists = createAction('organizations/nameExists')

export const resetNameExists = createAction('organizations/resetNameExists')

export const acronymExists = createAction('organizations/acronymExists')

export const resetAcronymExists = createAction(
  'organizations/resetAcronymExists'
)

export const nipExists = createAction('organizations/nipExists')

export const resetNipExists = createAction('organizations/resetNipExists')

export const createOrganization = createAsyncThunk(
  'organizations/create',
  async (values: OrganizationFormValues, { dispatch }) => {
    try {
      const response = await api.createOrganization(values)
      return response
    } catch (err: any) {
      if (err.response.status === 400) {
        if (err.response.data === 'nameExists') await dispatch(nameExists())
        else if (err.response.data === 'acronymExists')
          await dispatch(acronymExists())
        else if (err.response.data === 'nipExists') await dispatch(nipExists())
      }
      throw err
    }
  }
)

export const editOrganization = createAsyncThunk(
  'organizations/edit',
  async (
    payload: { values: OrganizationFormValues; id: number },
    { dispatch }
  ) => {
    try {
      const response = await api.editOrganization(payload.values, payload.id)
      return response
    } catch (err: any) {
      if (err.response.status === 400) {
        if (err.response.data === 'nameExists') await dispatch(nameExists())
        else if (err.response.data === 'acronymExists')
          await dispatch(acronymExists())
        else if (err.response.data === 'nipExists') await dispatch(nipExists())
      }
      throw err
    }
  }
)
