import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Api, GetWarrantiesParameters } from 'shared/services'
import {
  Warranty,
  EditMachine,
  WarrantiesSortOrder,
  WarrantyFilterDateType,
} from 'shared/types'

const api = new Api()

export const getOrganizationWithChildren = createAsyncThunk(
  'warranties/GetOrganizationWithChildren',
  async () => {
    const response = await api.GetOrganizationWithChildren()
    return response.data
  }
)
export const getOrganizations = createAsyncThunk(
  'warranties/getOrganizations',
  async () => {
    const response = await api.getOrganizationsFaster()
    return response.data
  }
)

export const getCountries = createAsyncThunk(
  'warranties/getCountries',
  async () => {
    const response = await api.getCountries()
    return response.data
  }
)

export const getMachineUsersWithChildren = createAsyncThunk(
  'warranties/getMachineUsers',
  async () => {
    const response = await api.getMachineUsersWithChildren()

    return response.data
  }
)

export const verifySerialNumber = createAsyncThunk(
  'warranties/verifySerialNumber',
  async (payload: { serialNumber: string; machineCode: string }) => {
    const { serialNumber, machineCode } = payload
    const response = await api.verifySerialNumber(serialNumber, machineCode)
    return response.data
  }
)

export const verifySerialNumberForWarrantyRegistration = createAsyncThunk(
  'warranties/VerifySerialNumberForWarrantyRegistration',
  async (payload: {
    serialNumber: string
    machineCode: string
    forceRegistration: boolean
  }) => {
    const { serialNumber, machineCode, forceRegistration } = payload
    const response = await api.verifySerialNumberForWarrantyRegistration(
      serialNumber,
      machineCode,
      forceRegistration
    )
    return response.data
  }
)

export const getMachineData = createAsyncThunk(
  'warranties/getMachineData',
  async (serialNumber: string) => {
    const response = await api.getMachineData(serialNumber)
    return response.data
  }
)

export const getCounties = createAsyncThunk(
  'warranties/getCounties',
  async (voivodeship?: string) => {
    const response = await api.getCountiesForWarranties(voivodeship)
    return response.data
  }
)

export const getVoivodeships = createAsyncThunk(
  'warranties/getVoivodeships',
  async (county?: string) => {
    const response = await api.getVoivodeshipsForWarranties(county)
    return response.data
  }
)

export const createWarranty = createAsyncThunk(
  'warranties/createWarranty',
  async (payload: {
    warranty: Warranty
    newUser: boolean
    forceRegistration: boolean
  }) => {
    const response = await api.createWarranty(
      payload.warranty,
      payload.newUser,
      payload.forceRegistration
    )
    return response.data
  }
)

export const getRegisteredWarranties = createAsyncThunk(
  'warranties/getRegisteredWarranties',
  async (parameters: GetWarrantiesParameters) => {
    const response = await api.getRegisteredWarranties(parameters)
    return response.data
  }
)
export const handleSelectedWarranty = createAction<Warranty>(
  'warranties/handleSelectedWarranty'
)

export const setPage = createAction<number>('warranties/setPage')

export const setPageSize = createAction<number>('warranties/setPageSize')

export const setOrder = createAction<WarrantiesSortOrder>('warranties/setOrder')

export const setMachineCodeFilter = createAction<string>(
  'warranties/setMachineCodeFilter'
)

export const setMachineTypeFilter = createAction<string>(
  'warranties/setMachineTypeFilter'
)

export const setDateType = createAction<WarrantyFilterDateType | undefined>(
  'warranties/setDateType'
)

export const setDateFrom = createAction<string>('warranties/setDateFrom')

export const setDateTo = createAction<string>('warranties/setDateTo')

export const setSerialNumber = createAction<string | undefined>(
  'warranties/setSerialNumber'
)

export const setDealerId = createAction<number | undefined>(
  'warranties/setDealerId'
)

export const resetFilters = createAction('warranties/resetFilters')

export const openDetailsDialog = createAction('warranties/openDetailsDialog')

export const closeDetailsDialog = createAction('warranties/closeDetailsDialog')

export const resetDialog = createAction('warranties/resetState')

export const goToEditMachine = createAction('warranties/goToEditMachine')

export const getMachineServiceRequests = createAsyncThunk(
  'warranties/getMachineServiceRequests',
  async (machineId: number) => {
    const response = await api.getMachineServiceRequests(machineId)
    return response.data
  }
)

export const editMachine = createAsyncThunk(
  'warranties/editMachine',
  async (
    payload: {
      warrantyId: number | undefined
      machine: EditMachine
    },
    { dispatch, rejectWithValue }
  ) => {
    const { warrantyId, machine } = payload
    const response = await api.editMachine(warrantyId, machine)

    return response.data
  }
)

export const getWarrantyPdfFile = createAsyncThunk(
  'warranties/getWarrantyPdfFile',
  async (payload: { warranty: Warranty; lng: string }) => {
    const { warranty, lng } = payload
    const response = await api.getWarrantyPdfFile(warranty, lng)
    return response.data
  }
)

export const resetIsCreated = createAction('warranties/resetIsCreated')
export const setIsWarrantyEdit = createAction('warranties/setIsWarrantyEdit')
export const resetIsEdit = createAction('warranties/resetIsEdit')
export const setUserExists = createAction<boolean>('warranties/setUserExists')
export const resetMachineData = createAction('warranties/resetMachineData')
export const setEditSaveBtnLock = createAction<boolean>(
  'warranties/setEditSabeBtnLock'
)

export const editWarranty = createAsyncThunk(
  'warranties/editWarranty',
  async (
    payload: {
      warrantyId: number | undefined
      warranty: Warranty
      newUser: boolean
    },
    { dispatch, rejectWithValue }
  ) => {
    const { warrantyId, warranty, newUser } = payload
    const response = await api.editWarranty(warrantyId, warranty, newUser)
    // dispatch(getRegisteredWarranties())
    return response.data
  }
)

export const getMachinesWithWarranties = createAsyncThunk(
  'warranties/getMachinesWithWarranties',
  async () => {
    const response = await api.getMachinesWithWarranties()
    return response.data
  }
)

export const resetAllState = createAction('warranties/resetAllState')

export const setCountries = createAction<string[]>(
  'serviceRequests/setCountries'
)

export const setVoivodeship = createAction<string | undefined>(
  'serviceRequests/setVoivodeship'
)

export const setCounty = createAction<string | undefined>(
  'serviceRequests/setCounty'
)

export const toggleFiltersVisibility = createAction(
  'warranties/toggleFiltersVisibility'
)
