import { createReducer } from '@reduxjs/toolkit'
import { LoadingStatus, Report } from 'shared/types'
import { getReport } from './actions'

export interface State {
  loading: LoadingStatus
  error?: string | null
  report: Report | undefined
}

const initialState: State = {
  loading: LoadingStatus.Idle,
  error: null,
  report: undefined,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getReport.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = null
    })
    .addCase(getReport.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.report = action.payload.data
    })
    .addCase(getReport.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
)
