import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Api, GetAnnouncementsParameters } from 'shared/services'
import { AnnouncementsSortOrder } from 'shared/types'
import { AnnouncementFormValues } from '../components/AnnouncementForm/AnnouncementForm.utils'

const api = new Api()

export const createAnnouncement = createAsyncThunk(
  'announcements/createAnnouncemet',
  async (values: AnnouncementFormValues) => {
    const response = await api.createAnnouncement(values)
    return response
  }
)

export const deleteAnnouncement = createAsyncThunk(
  'announcements/deleteAnnouncement',
  async (id: number) => {
    const response = await api.deleteAnnouncement(id)
    return response
  }
)

export const resetState = createAction('announcements/reset')

export const setPage = createAction<number>('announcements/setPage')

export const resetDeleted = createAction('announcements/resetDeleted')

export const setOrder = createAction<AnnouncementsSortOrder>(
  'announcements/setOrder'
)

export const getAnnouncements = createAsyncThunk(
  'announcements/getAnnouncements',
  async (parameters: GetAnnouncementsParameters) => {
    const response = await api.getAnnouncements(parameters)
    return response.data
  }
)

export const getAnnouncementById = createAsyncThunk(
  'announcements/getAnnouncementById',
  async (id: number) => {
    const response = await api.getAnnouncementById(id)
    return response.data
  }
)

export const editAnnouncement = createAsyncThunk(
  'announcements/editAnnouncement',
  async (payload: { id: number; values: AnnouncementFormValues }) => {
    const { id, values } = payload
    const response = await api.editAnnouncement(id, values)
    return response.data
  }
)
