import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Auth } from 'shared/services'
import { UserRoles } from 'shared/types'
import { RootState } from '../../app'
import { selectors } from '../store'

function checkIfUser(roles: UserRoles | undefined, role: UserRoles): boolean {
  return roles === role
}

export const useAuth = () => {
  const isAuthenticated = useSelector<RootState, boolean>(
    selectors.auth.getIsAuthenticated
  )
  const tokenData = Auth.getTokenData()

  const isMainAdministrator = checkIfUser(
    tokenData?.role,
    UserRoles.MainAdministrator
  )
  const isD1Administrator = checkIfUser(
    tokenData?.role,
    UserRoles.D1Administrator
  )
  const isD1Employee = checkIfUser(tokenData?.role, UserRoles.D1Employee)
  const isSamaszEmployee = checkIfUser(
    tokenData?.role,
    UserRoles.SamaszEmployee
  )
  const isSamaszTrader = checkIfUser(tokenData?.role, UserRoles.SamaszTrader)
  const isD2Administrator = checkIfUser(
    tokenData?.role,
    UserRoles.D2Administrator
  )
  const isD2Employee = checkIfUser(tokenData?.role, UserRoles.D2Employee)
  const isSamaszUser =
    checkIfUser(tokenData?.role, UserRoles.SamaszEmployee) ||
    checkIfUser(tokenData?.role, UserRoles.SamaszTrader) ||
    checkIfUser(tokenData?.role, UserRoles.MainAdministrator)

  return {
    isAuthenticated,
    logout: Auth.logout,
    tokenData,
    isMainAdministrator,
    isD1Administrator,
    isD1Employee,
    isSamaszEmployee,
    isSamaszTrader,
    isD2Administrator,
    isD2Employee,
    isSamaszUser,
  }
}
