import type { RootState } from 'app'

export const getUserList = (state: RootState) => state.users.userList

export const getOrganizations = (state: RootState) =>
  state.users.organizationList

export const getCountries = (state: RootState) => state.users.countries

export const isLoading = (state: RootState) => state.users.loading

export const getError = (state: RootState) => state.users.error

export const getIndetifier = (state: RootState) => state.users.identifier

export const getIsUserCreated = (state: RootState) => state.users.isUserCreated

export const getIsUserEdited = (state: RootState) => state.users.isUserEdited

