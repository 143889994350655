import { Country } from '../types/interfaces'

export const countries = (countries: Country[]) => {
  const countriesDictionary: { [id: number]: Country } = {}

  countries.forEach(item => {
    countriesDictionary[item.id] = item
  })

  return countriesDictionary
}
