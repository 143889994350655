import { createReducer } from '@reduxjs/toolkit'
import {
  acronymExists,
  resetAcronymExists,
} from 'modules/organizations/store/actions'
import {
  LoadingStatus,
  Organization,
  MachineUser,
  Country,
  MachineDetails,
  VerifySerialNumberResponse,
  MachineDataResponse,
} from 'shared/types'
import {
  closeDetailsDialog,
  getMachineUsers,
  getOrganizations,
  goToEditMachineUser,
  openDetailsDialog,
  resetDialog,
  selectUserToSeeDetails,
  getCountries,
  editMachineUser,
  getMachineDetails,
  setIsMachineModalOpen,
  editMachine,
  getMachineData,
  verifySerialNumber,
  setIsMachineEdit,
} from './actions'

export interface State {
  machineUserList: MachineUser[]
  loading: LoadingStatus
  error?: string | null
  organizations: Organization[]
  countries: Country[]
  isDetailsDialogOpen: boolean
  selectedUserToDetails: MachineUser | null
  isUserEditing: boolean
  acronymExists: boolean
  isMachineDetailsDialogOpen: boolean
  isMachineEdit: boolean
  selectedMachineId?: number
  selectedMachine?: MachineDetails
  verifySerialNumber: VerifySerialNumberResponse | null
  machineData: MachineDataResponse[] | null
  editSaveBtnLock: boolean
}

const initialState: State = {
  machineUserList: [],
  loading: LoadingStatus.Idle,
  error: null,
  organizations: [],
  countries: [],
  isDetailsDialogOpen: false,
  selectedUserToDetails: null,
  isUserEditing: false,
  acronymExists: false,
  isMachineDetailsDialogOpen: false,
  isMachineEdit: false,
  verifySerialNumber: null,
  machineData: null,
  editSaveBtnLock: false,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getMachineUsers.pending, state => {
      state.machineUserList = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getMachineUsers.fulfilled, (state, action) => {
      state.machineUserList = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getMachineUsers.rejected, (state, action) => {
      state.machineUserList = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getOrganizations.pending, state => {
      state.organizations = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getOrganizations.fulfilled, (state, action) => {
      state.organizations = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getOrganizations.rejected, (state, action) => {
      state.organizations = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getCountries.pending, state => {
      state.countries = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getCountries.rejected, (state, action) => {
      state.countries = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(editMachineUser.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(editMachineUser.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.isUserEditing = false
      state.selectedUserToDetails = null
      state.isDetailsDialogOpen = false
      state.error = null
    })
    .addCase(editMachineUser.rejected, (state, action) => {
      if (action.payload === 400) state.loading = LoadingStatus.Succeeded
      else {
        state.loading = LoadingStatus.Failed
        state.error = action.error.message
      }
    })
    .addCase(selectUserToSeeDetails, (state, action) => {
      state.selectedUserToDetails = action.payload
    })
    .addCase(openDetailsDialog, state => {
      state.isDetailsDialogOpen = true
    })
    .addCase(closeDetailsDialog, state => {
      state.isDetailsDialogOpen = false
      state.isUserEditing = false
      state.isMachineDetailsDialogOpen = false
      state.selectedMachine = undefined
      state.isMachineEdit = false
    })
    .addCase(resetDialog, state => {
      state.isDetailsDialogOpen = false
      state.selectedUserToDetails = null
      state.isUserEditing = false
      state.acronymExists = false
    })
    .addCase(goToEditMachineUser, state => {
      state.isUserEditing = true
    })
    .addCase(acronymExists, state => {
      state.acronymExists = true
    })
    .addCase(resetAcronymExists, state => {
      state.acronymExists = false
    })
    .addCase(setIsMachineEdit, (state, action) => {
      state.isMachineEdit = action.payload
    })
    .addCase(getMachineDetails.pending, state => {
      state.selectedMachine = undefined
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getMachineDetails.fulfilled, (state, action) => {
      state.selectedMachine = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getMachineDetails.rejected, (state, action) => {
      state.selectedMachine = undefined
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(setIsMachineModalOpen, (state, action) => {
      state.isMachineDetailsDialogOpen = action.payload
    })
    .addCase(editMachine.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(editMachine.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.isMachineEdit = false
      state.selectedMachine = undefined
      state.isMachineDetailsDialogOpen = false
      state.isUserEditing = false
      state.isDetailsDialogOpen = false
      state.error = null
    })
    .addCase(editMachine.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getMachineData.pending, state => {
      state.machineData = null
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getMachineData.fulfilled, (state, action) => {
      state.machineData = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getMachineData.rejected, (state, action) => {
      state.machineData = null
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(verifySerialNumber.pending, state => {
      state.verifySerialNumber = null
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(verifySerialNumber.fulfilled, (state, action) => {
      state.verifySerialNumber = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(verifySerialNumber.rejected, (state, action) => {
      state.verifySerialNumber = null
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
)
