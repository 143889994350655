import createPalette, {
  PaletteOptions,
} from '@material-ui/core/styles/createPalette'

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    neutral: Palette['primary']
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary']
  }
}

export const palette: PaletteOptions = {
  primary: {
    light: '#9BD193',
    main: '#48923D',
    dark: '#45812B',
    contrastText: '#FFF',
  },
  secondary: {
    light: '#707070',
    main: '#615E5E',
    dark: '#4D4D4D',
    contrastText: '#FFF',
  },
  neutral: {
    light: '#EDEDED',
    main: '#E3E3E3',
    dark: '#C9C9C9',
    contrastText: '#000',
  },
  background: {
    default: '#F9F9F9',
  },
}

export default createPalette(palette)
