export enum LoadingStatus {
  Idle = 'idle',
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export enum OrganizationTypes {
  D1 = 0,
  D2 = 1,
  D3 = 2,
  Samasz = 3,
  D3_1 = 4,
}

export enum UserStatus {
  Active = 0,
  WaitingForActivation = 1,
  Blocked = 2,
  Deleted = 3,
}

export enum OrganizationStatus {
  Active = 0,
  Blocked = 1,
}

export enum ServiceRequestStatusesInt {
  NoweZgloszenie = 1,
  ZgloszenieNiekompletne = 2,
  ZgloszenieOdrzucone = 3,
  WTrakcieAnalizy = 4,
  WRealizacji = 5,
  ZgloszeniePoprawione = 6,
  ZgloszenieZakonczone = 7,
  CzesciwTrakcieProdukcji = 8,
  CzesciwTrakcieProjektowanaPrototyp = 9,
  CzesciPrzygotowywanedoWysyłki = 10,
  ZaplanowanaWizytaSerwisuFabrycznego = 11,
  CzesciReklamacyjneWysłanedoDilera = 12,
  CzesciReklamacyjneWysłanedoKlienta = 13,
  TransportMaszynydoSaMASZ = 14,
  NaprawaMaszynywSerwisieSaMASZ = 15,
  MaszynaDostarczonadoKlienta = 16,
  NaprawaZrealizowanaPrzezSerwisFabryczny = 17,
  Usuniete = 18,
  Inne = 19,
  FakturaZaNaprawe = 20,
  AnalizaPoStronieDilera = 21,
}

export enum UserRoles {
  MainAdministrator = 'MainAdministrator',
  SamaszEmployee = 'SamaszEmployee',
  SamaszTrader = 'SamaszTrader',
  D1Administrator = 'D1Administrator',
  D2Administrator = 'D2Administrator',
  D3Administrator = 'D3Administrator',
  D3_1Administrator = 'D3_1Administrator',
  D1Employee = 'D1Employee',
  D2Employee = 'D2Employee',
  D3Employee = 'D3Employee',
  D3_1Employee = 'D3_1Employee',
}

export enum WarrantyFilterDateType {
  SaleDate = 0,
  EndWarranty = 1,
}

export enum RegistrationStatus {
  Registered = 0,
  Verification = 1,
  ForApproval = 2,
}

export enum WarrantiesSortOrder {
  Default,
  NameASC,
  NameDESC,
  MachineCodeASC,
  MachineCodeDESC,
  MachineTypeASC,
  MachineTypeDESC,
  SerialNumberASC,
  SerialNumberDESC,
  MachineSaleDateASC,
  MachineSaleDateDESC,
  RegisteredByASC,
  RegisteredByDESC,
  AssignedToASC,
  AssignedToDESC,
  EndWarrantyASC,
  EndWarrantyDESC,
}

export enum ServiceRequestSortOrder {
  Default,
  RequestNumberASC,
  RequestNumberDESC,
  DealerNameASC,
  DealerNameDESC,
  MachineTypeASC,
  MachineTypeDESC,
  SerialNumberASC,
  SerialNumberDESC,
  DescriptionASC,
  DescriptionDESC,
  FailureDateASC,
  FailureDateDESC,
  ModificationDateASC,
  ModificationDateDESC,
  MachineUserASC,
  MachineUserDESC,
  StreetASC,
  StreetDESC,
  CityASC,
  CityDESC,
  PostalCodeASC,
  PostalCodeDESC,
  NipASC,
  NipDESC,
  PhoneNumberASC,
  PhoneNumberDESC,
  ContactPersonASC,
  ContactPersonDESC,
  EmailASC,
  EmailDESC,
  RegionASC,
  RegionDESC,
  CountyASC,
  CountyDESC,
  WorkedAcreageASC,
  WorkedAcreageDESC,
  VehicleTypeASC,
  VehicleTypeDESC,
  RepairMethodASC,
  RepairMethodDESC,
  StatusASC,
  StatusDESC,
  CauseASC,
  CauseDESC,
  CountryASC,
  CountryDESC,
  TypeASC,
  TypeDESC,
}

export enum LogType {
  newRequest,
  unCompleteRequest,
}

export enum ServiceRequestsStatuses {
  newRequest = 'Nowe zgłoszenie',
  incompleteRequest = 'Zgłoszenie niekompletne',
  rejectedRequests = 'Zgłoszenie odrzucone',
  inAnalysis = 'W trakcie analizy',
  inProgress = 'W realizacji',
  correctedRequests = 'Zgłoszenie poprawione',
  finishedRequest = 'Zgłoszenie zakończone',
  productionParts = 'Części w trakcie produkcji',
  prototype = 'Części w trakcie projektowana – prototyp',
  preparingParts = 'Części przygotowywane do wysyłki',
  serviceSend = 'Serwis wysłany (w przypadku naprawy serwisowej)',
  partsSendToDealer = 'Części reklamacyjne wysłane do Dilera',
  partsSendToClient = 'Części reklamacyjne wysłane do Klienta',
  transportToSamasz = 'Transport Maszyny do SaMASZ',
  repairMachineInSamasz = 'Naprawa Maszyny w serwisie SaMASZ',
  machineDeliveredToClient = 'Maszyna dostarczona do Klienta',
  removedRequest = 'Zgłoszenie usunięte',
  other = 'Inne',
  claimInvoiced = 'Faktura za naprawę',
  claimProcessedByDealer = 'Analiza po stronie dilera',
}

export enum ServiceRequestDetailsScrollTo {
  NoScroll,
  Comments,
  Statuses,
}

export enum ServiceRequestTypes {
  WarrantyRepair = 0,
  SparePartComplaint = 1,
  PaidRepair = 2,
  MachineComplaintInDealerStock = 3,
  CostComplaint = 4,
}

export enum AlertType {
  ServiceRequest,
  Organization,
}

export enum OrganizationAlertReason {
  AddOrganization,
}

export enum ServiceRequestAlertReason {
  New,
  Incomplete,
  InProgress,
  SubstatusChanged,
  Rejected,
  Finished,
  Corrected,
  Deleted,
  CommentAdded,
  ClaimProcessedByDealer,
}

export enum MachineActionType {
  WarrantyRegistered,
  WarrantyEdited,
  NewRequest,
  MachineEdited,
}

export enum AnnouncementsSortOrder {
  Default,
  ActiveASC,
  ActiveDESC,
}
