import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  Api,
  ChangeServiceRequestStatus,
  GetServiceRequestsParameters,
} from 'shared/services'
import {
  Organization,
  ServiceRequestBase,
  ServiceRequestSortOrder,
  ServiceRequestReasonFormValues,
  ServiceRequestCommentFormValues,
  ServiceRequestDetailsScrollTo,
  ServiceRequestListFilters,
} from 'shared/types'
import { Columns } from '../components/Filters/Filters.utils'
import { ServiceRequestFormValues } from '../components/ServiceRequestDetailsForm/ServiceRequestDetailsForm.utils'
const api = new Api()

export const getOrganizationWithChildren = createAsyncThunk(
  'serviceRequests/GetOrganizationWithChildren',
  async () => {
    const response = await api.GetOrganizationWithChildren()
    return response.data
  }
)

export const getCountries = createAsyncThunk(
  'serviceRequests/getCountries',
  async () => {
    const response = await api.getCountries()
    return response.data
  }
)

export const getMachineUsersWithChildren = createAsyncThunk(
  'serviceRequests/getMachineUsers',
  async () => {
    const response = await api.getMachineUsersWithChildren()
    return response.data
  }
)

export const verifySerialNumber = createAsyncThunk(
  'serviceRequests/verifySerialNumber',
  async (payload: { serialNumber: string; machineCode: string }) => {
    const { serialNumber, machineCode } = payload
    const response = await api.verifySerialNumber(serialNumber, machineCode)
    return response.data
  }
)

export const getMachineData = createAsyncThunk(
  'serviceRequests/getMachineData',
  async (serialNumber: string) => {
    const response = await api.getMachineData(serialNumber)
    return response.data
  }
)

export const getMachineBySerialNumberAndMachineCode = createAsyncThunk(
  'serviceRequests/getMachineBySerialNumber',
  async (payload: { serialNumber: string; machineCode: string }) => {
    const { serialNumber, machineCode } = payload
    const response = await api.getMachineDataWithUser(serialNumber, machineCode)
    return response.data
  }
)

export const getServiceRequestPdfFile = createAsyncThunk(
  'serviceRequests/getServiceRequestPdfFile',
  async (payload: { id: number; type: number; language: string }) => {
    const response = await api.getServiceRequestPdfFile({ ...payload })
    return response.data
  }
)

export const handleSelectedDealer = createAction<Organization>(
  'serviceRequests/handleSelectedDealer'
)

export const handleMachineUserIsDealer = createAction<boolean>(
  'serviceRequests/handleMachineUserIsDealer'
)

export const createServiceRequest = createAsyncThunk(
  'serviceRequests/createServiceRequest',
  async (payload: {
    serviceRequest: ServiceRequestBase
    newUser: boolean
    machineFromErp?: boolean | null
  }) => {
    const { serviceRequest, newUser, machineFromErp } = payload
    const response = await api.createServiceRequest(
      serviceRequest,
      newUser,
      machineFromErp
    )
    return response.data
  }
)

export const updateServiceRequest = createAsyncThunk(
  'serviceRequests/updateServiceRequest',
  async (serviceRequest: ServiceRequestFormValues) => {
    const response = await api.updateServiceRequest(serviceRequest)
    if (response.status !== 200) return response.data
    const secondresponse = await api.updateServiceRequestDocumentantion(
      serviceRequest
    )
    return secondresponse.data
  }
)

export const createReasonsRequest = createAsyncThunk(
  'serviceRequests/createReasonsRequest',
  async (reasonsRequest: ServiceRequestReasonFormValues) => {
    const response = await api.createReasonsRequest(reasonsRequest)
    return response.data
  }
)

export const createCommentRequest = createAsyncThunk(
  'serviceRequests/createCommentRequest',
  async (commentRequest: ServiceRequestCommentFormValues) => {
    const response = await api.createSamaszCommentRequest(commentRequest)
    return response.data
  }
)

export const resetState = createAction('serviceRequests/reset')
export const setIsCreated = createAction('serviceRequests/setIsCreated')

export const setSelectedColumns = createAction<Columns[]>(
  'serviceRequests/setSelectedColumns'
)

export const getServiceRequests = createAsyncThunk(
  'serviceRequests/getServiceRequests',
  async (parameters: GetServiceRequestsParameters) => {
    const response = await api.getServiceRequests(parameters)
    return response.data
  }
)

export const getDeliverers = createAsyncThunk(
  'serviceRequests/getDeliverers',
  async () => {
    const response = await api.getDeliverers()
    return response.data
  }
)

export const getMachinesWithServiceRequests = createAsyncThunk(
  'serviceRequests/getMachinesWithServiceRequests',
  async () => {
    const response = await api.getMachinesWithServiceRequests()
    return response.data
  }
)

export const getServiceRequestsStatuses = createAsyncThunk(
  'serviceRequests/getServiceRequestsStatuses',
  async () => {
    const response = await api.getServiceRequestStatuses()
    return response.data
  }
)

export const getVehicleTypes = createAsyncThunk(
  'serviceRequests/getVehicleTypes',
  async () => {
    const response = await api.getVehicleTypes()
    return response.data
  }
)

export const getDescriptions = createAsyncThunk(
  'serviceRequests/getDescriptions',
  async () => {
    const response = await api.getDescriptions()
    return response.data
  }
)

export const insertSamaszComment = createAsyncThunk(
  'serviceRequests/insertSamaszComment',
  async (comment: ServiceRequestCommentFormValues) => {
    const response = await api.createCommentRequest(comment)
    return response.data
  }
)

export const setPage = createAction<number>('serviceRequests/setPage')

export const setPageSize = createAction<number>('serviceRequests/setPageSize')

export const setOrder = createAction<ServiceRequestSortOrder>(
  'serviceRequests/setOrder'
)

export const setAllTextSearch = createAction<string>(
  'serviceRequests/setAllTextSearch'
)
export const setDealer = createAction<string[]>('serviceRequests/setDealer')

export const setSerialNumber = createAction<string[]>(
  'serviceRequests/setSerialNumber'
)

export const setMachineCode = createAction<string[]>(
  'serviceRequests/setMachineType'
)

export const setDescription = createAction<string[]>(
  'serviceRequests/setDescription'
)

export const setVehicleType = createAction<string[]>(
  'serviceRequests/setVehicleType'
)

export const setType = createAction<number[]>('serviceRequests/setType')

export const setStatus = createAction<number[]>('serviceRequests/setStatus')

export const setDateFrom = createAction<string>('serviceRequests/setDateFrom')

export const setDateTo = createAction<string>('serviceRequests/setDateTo')

export const setCountries = createAction<string[]>(
  'serviceRequests/setCountries'
)

export const setFilters = createAction<ServiceRequestListFilters>(
  'serviceRequests/setFilters'
)

export const getSelectedServiceRequest = createAsyncThunk(
  'serviceRequests/getSelectedServiceRequest',
  async (
    payload: {
      id: number
      serviceRequestType: number
    },
    { dispatch, rejectWithValue }
  ) => {
    const { id, serviceRequestType } = payload
    const response = await api.getSelectedServiceRequest(id, serviceRequestType)
    return { request: response.data, id: id, type: serviceRequestType }
  }
)

export const goToDetails = createAction<{
  id: number
  serviceRequestType: number
  isCorrecting?: boolean
}>('serviceRequests/goToDetails')

export const openRequestReasonModal = createAction(
  'serviceRequests/openRequestReasonModal'
)

export const closeRequestReasonModal = createAction(
  'serviceRequests/closeRequestReasonModal'
)

export const openCommentModal = createAction('serviceRequests/openCommentModal')
export const resetFormCorrecting = createAction(
  'serviceRequests/resetFormCorrecting'
)

export const closeCommentModal = createAction(
  'serviceRequests/closeCommentModal'
)

export const resetGoToDetails = createAction('serviceRequests/resetGoToDetails')

export const resetIsDetails = createAction('serviceRequests/resetIsDetails')

export const resetMachineData = createAction('serviceRequests/resetMachineData')

export const openBadSerialNumberModal = createAction(
  'serviceRequests/openBadSerialNumberModal'
)

export const closeBadSerialNumberModal = createAction(
  'serviceRequests/closeBadSerialNumberModal'
)

export const setUserExists = createAction<boolean>(
  'serviceRequests/setUserExists'
)

export const scrollTo = createAction<ServiceRequestDetailsScrollTo>(
  'serviceRequests/scrollTo'
)

export const setIsIncompleteTypeModalOpen = createAction<boolean>(
  'serviceRequests/setIsIncompleteTypeModalOpen'
)

export const setIsRejectTypeModalOpen = createAction<boolean>(
  'serviceRequests/setIsRejectTypeModalOpen'
)

export const changeStatus = createAsyncThunk(
  'serviceRequests/changeStatus',
  async (changeStatusDto: ChangeServiceRequestStatus) => {
    const response = await api.changeServiceRequestStatus(changeStatusDto)
    return response.data
  }
)

export const changeRequestToPaidRepair = createAsyncThunk(
  'serviceRequests/changeRequestToPaidRepair',
  async (id: number) => {
    const response = await api.ChangeRequestToPaidRepair(id)
    return response.data
  }
)

export const getOrganizationsHavingRequests = createAsyncThunk(
  'serviceRequests/getOrganizationsHavingRequests',
  async () => {
    const response = await api.getOrganizationsHavingRequests()
    return response.data
  }
)

export const getServiceRequestSerialNumbers = createAsyncThunk(
  'serviceRequests/getServiceRequestSerialNumbers',
  async () => {
    const response = await api.getServiceRequestSerialNumbers()
    return response.data
  }
)

export const getEndWarranty = createAsyncThunk(
  'serviceRequests/getEndWarranty',
  async (payload: {
    serialNumber: string
    machineCode: string
    saleDate: Date | null
  }) => {
    const { serialNumber, machineCode, saleDate } = payload
    const response = await api.getEndWarranty(
      serialNumber,
      machineCode,
      saleDate
    )
    return response.data
  }
)

export const resetMachine = createAction('serviceRequests/resetMachine')

export const setNeedFetchData = createAction('serviceRequests/setNeedFetchData')

export const resetAllFilters = createAction('serviceRequests/resetAllFilters')

export const reloadDetails = createAction('serviceRequests/reloadDetails')

export const resetAllState = createAction('serviceRequests/resetAllState')

export const setIsCorrecting = createAction<boolean>(
  'serviceRequests/setIsCorrecting'
)

export const openGallery = createAction('serviceRequests/openGallery')

export const closeGallery = createAction('serviceRequests/closeGallery')

export const toggleFiltersVisibility = createAction(
  'serviceRequests/toggleFiltersVisibility'
)
