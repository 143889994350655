import config from 'config'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { SignedIdUser, tokenData, UserRoles } from 'shared/types'

class Auth {
  static setToken(token: string) {
    localStorage.setItem(config.storageTokenKey, token)
    localStorage.setItem(
      config.tokenExpiration,
      (new Date().getTime() + 8 * 60 * 60000).toString()
    )
  }

  static getToken() {
    const expiration = localStorage.getItem(config.tokenExpiration)
    if (!expiration) return null
    if (new Date().getTime() > Number.parseInt(expiration)) {
      this.logout()
      return null
    }
    return localStorage.getItem(config.storageTokenKey)
  }

  static getTokenData() {
    const token = Auth.getToken()
    if (token !== null) {
      const data = jwt_decode<tokenData>(token)

      const role: UserRoles = (<any>UserRoles)[data.role]

      const user: SignedIdUser = {
        id: Number.parseInt(data.nameid),
        role: role,
        name: data.given_name,
        identifier: data.unique_name,
        surname: data.family_name,
        countryId: Number.parseInt(data.countryId),
        organizationId: Number.parseInt(data.organizationId),
      }

      return user
    }
  }

  static isAuthenticated() {
    return !!Auth.getToken()
  }

  static logout() {
    localStorage.removeItem(config.storageTokenKey)
    localStorage.removeItem(config.tokenExpiration)
    window.location.reload()
  }
}

export default Auth
