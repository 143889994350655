import config from 'config'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import AnnouncementIcon from '@material-ui/icons/Announcement'

const routes: ModuleRoute[] = [
  {
    path: '/addAnnouncement',
    exact: true,
    component: Loadable({
      component: () => import('./pages/AddAnnouncement'),
    }),
    needUserName: config.announcementsUserName,
  },
  {
    path: '/editAnnouncement/:id',
    exact: true,
    component: Loadable({
      component: () => import('./pages/EditAnnouncement'),
    }),
    needUserName: config.announcementsUserName,
  },
  {
    path: '/announcements',
    exact: true,
    component: Loadable({
      component: () => import('./pages/Announcements'),
    }),
    needUserName: config.announcementsUserName,
    layoutSettings: {
      label: 'navigation.announcements',
      icon: AnnouncementIcon,
    },
  },
]

export default routes
