import { Description } from '@material-ui/icons'
import { Loadable } from 'shared/components'
import { ModuleRoute, UserRoles } from 'shared/types'
import { RegisteredWarrantiesIcon } from 'shared/Icons/Icons'

const routes: ModuleRoute[] = [
  {
    path: '/register-warranty',
    exact: true,
    layoutSettings: {
      icon: Description,
      label: 'navigation.warrantyRegistration',
    },
    component: Loadable({
      component: () => import('./pages/WarrantyRegistration'),
    }),
    needRole: [
      UserRoles.MainAdministrator,
      UserRoles.SamaszEmployee,
      UserRoles.D1Administrator,
      UserRoles.D1Employee,
      UserRoles.D2Administrator,
      UserRoles.D2Employee,
      UserRoles.D3Administrator,
      UserRoles.D3Employee,
      UserRoles.D3_1Administrator,
      UserRoles.D3_1Employee,
    ],
  },
  {
    path: '/registeredWarranties',
    exact: true,
    layoutSettings: {
      icon: RegisteredWarrantiesIcon,
      label: 'navigation.registeredWarranties',
    },
    component: Loadable({
      component: () => import('./pages/RegisteredWarranties'),
    }),
  },
  {
    path: '/edit-warranty',
    exact: true,
    component: Loadable({
      component: () => import('./pages/EditWarranty'),
    }),
    needRole: [
      UserRoles.MainAdministrator,
      UserRoles.SamaszEmployee,
      UserRoles.D1Administrator,
      UserRoles.D1Employee,
      UserRoles.D2Administrator,
      UserRoles.D2Employee,
      UserRoles.D3Administrator,
      UserRoles.D3Employee,
      UserRoles.D3_1Administrator,
      UserRoles.D3_1Employee,
    ],
  },
]

export default routes
