import { Loadable } from 'shared/components'
import { ModuleRoute, UserRoles } from 'shared/types'
import { ServiceIcon, ServiceRequestsIcon } from 'shared/Icons/Icons'

const routes: ModuleRoute[] = [
  {
    path: '/addServiceRequest',
    exact: true,
    layoutSettings: {
      icon: ServiceIcon,
      label: 'navigation.addServiceRequest',
    },
    component: Loadable({
      component: () => import('./pages/AddServiceRequest'),
    }),
    needRole: [
      UserRoles.MainAdministrator,
      UserRoles.SamaszEmployee,
      UserRoles.D1Administrator,
      UserRoles.D1Employee,
      UserRoles.D2Administrator,
      UserRoles.D2Employee,
      UserRoles.D3_1Administrator,
      UserRoles.D3_1Employee,
    ],
  },
  {
    path: '/serviceRequestList',
    exact: true,
    layoutSettings: {
      label: 'navigation.serviceRequests',
      icon: ServiceRequestsIcon,
      needSpace: true,
    },
    component: Loadable({
      component: () => import('./pages/ServiceRequestList'),
    }),
    needRole: [
      UserRoles.MainAdministrator,
      UserRoles.SamaszEmployee,
      UserRoles.D1Administrator,
      UserRoles.D1Employee,
      UserRoles.D2Administrator,
      UserRoles.D2Employee,
      UserRoles.D3_1Administrator,
      UserRoles.D3_1Employee,
    ],
  },
  {
    path: '/serviceRequestDetails/:id/:type',
    exact: true,
    component: Loadable({
      component: () => import('./pages/ServiceRequestDetails'),
    }),
    needRole: [
      UserRoles.MainAdministrator,
      UserRoles.SamaszEmployee,
      UserRoles.D1Administrator,
      UserRoles.D1Employee,
      UserRoles.D2Administrator,
      UserRoles.D2Employee,
      UserRoles.D3_1Administrator,
      UserRoles.D3_1Employee,
    ],
  },
]

export default routes
