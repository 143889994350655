import type { RootState } from 'app'

export const isLoading = (state: RootState) => state.alerts.loading

export const getError = (state: RootState) => state.alerts.error

export const getAlerts = (state: RootState) => state.alerts.alerts

export const getSelectedAlertsIds = (state: RootState) =>
  state.alerts.selectedAlerts

export const getCountries = (state: RootState) => state.alerts.countries
