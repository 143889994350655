import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { Api } from 'shared/services'

const api = new Api()

export const resetPassword = createAsyncThunk(
  'account/resetPassword',
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await api.resetPassword(email)
      return response.data
    } catch (err) {
      if (!err.response) return rejectWithValue('defaultError')
      return rejectWithValue(err.response.data)
    }
  }
)

export const resetState = createAction('dashboard/reset')

export const getAnnouncements = createAsyncThunk(
  'dashboard/getAnnouncements',
  async () => {
    const response = await api.getActualAnnouncements()
    return response.data
  }
)
