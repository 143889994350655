import { PropsWithChildren, useEffect, useState } from 'react'
import { SvgIconComponent } from '@material-ui/icons'
import { ModuleRoute } from 'shared/types'
import Topbar, { TopbarFix } from './Topbar'
import Drawer, { CLOSED_DRAWER_WIDTH, OPEN_DRAWER_WIDTH } from './Drawer'
import { Container, Content } from './Layout.style'
import { useCurrentLayoutSettings } from './Layout.utils'
import SnackbarAlert from '../SnackbarAlert'
import { useAlert } from 'shared/hooks'
import { useSelector } from 'react-redux'
import { selectors } from 'shared/store'
import { useTranslation } from 'react-i18next'

export interface LayoutNavItem {
  path: string
  label: string
  icon?: SvgIconComponent
}

export interface LayoutProps {
  routes: ModuleRoute[]
  hideDrawer?: boolean
  hideTopbar?: boolean
}

const Layout = ({ routes, children }: PropsWithChildren<LayoutProps>) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const {
    drawerProps = {},
    topbarProps = {},
    width100percents,
  } = useCurrentLayoutSettings(routes)

  const navItems = routes.filter(
    ({ layoutSettings: { label, icon } = {} }) => label || icon
  )

  const openDrawer = () => setIsDrawerOpen(true)
  const closeDrawer = () => setIsDrawerOpen(false)

  const width = isDrawerOpen ? OPEN_DRAWER_WIDTH : CLOSED_DRAWER_WIDTH

  const haveAlerts = useSelector(selectors.alerts.getHasNewAlerts)
  const [isAlertOpen, openAlert, closeAlert] = useAlert()
  useEffect(() => {
    if (haveAlerts) openAlert()
  }, [haveAlerts])

  const { t } = useTranslation()

  return (
    <Container
      style={{
        maxWidth: '100vw',
      }}
    >
      <Topbar
        shifted={!drawerProps.hidden && isDrawerOpen}
        menuButtonHidden={drawerProps.hidden}
        onMenuButtonClick={openDrawer}
        {...topbarProps}
      />
      <Drawer
        open={isDrawerOpen}
        navItems={navItems}
        onClose={closeDrawer}
        {...drawerProps}
      />
      <Content
        style={{
          maxWidth:
            !drawerProps?.hidden && !width100percents
              ? `calc(100% - ${width + 20}px)`
              : '100%',
        }}
      >
        {!topbarProps.hidden && <TopbarFix />}
        <SnackbarAlert
          message={t('notifications.newAlerts')}
          open={isAlertOpen}
          color="info"
          handleClose={closeAlert}
        />
        {children}
      </Content>
    </Container>
  )
}

export default Layout
