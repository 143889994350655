import type { RootState } from 'app'

export const getMachineUserList = (state: RootState) =>
  state.machineUsers.machineUserList

export const getOrganizations = (state: RootState) =>
  state.machineUsers.organizations

export const isLoading = (state: RootState) => state.machineUsers.loading

export const getError = (state: RootState) => state.machineUsers.error

export const isDetailsDialogOpen = (state: RootState) =>
  state.machineUsers.isDetailsDialogOpen

export const geSelectedMachineUser = (state: RootState) =>
  state.machineUsers.selectedUserToDetails

export const isUserEditing = (state: RootState) =>
  state.machineUsers.isUserEditing

export const getCountries = (state: RootState) => state.machineUsers.countries

export const isMachineDetailDialogOpen = (state: RootState) =>
  state.machineUsers.isMachineDetailsDialogOpen

export const isMachineEdit = (state: RootState) =>
  state.machineUsers.isMachineEdit

export const selectedMachine = (state: RootState) =>
  state.machineUsers.selectedMachine

export const getVerifySerialNumber = (state: RootState) =>
  state.machineUsers.verifySerialNumber

export const getMachineData = (state: RootState) =>
  state.machineUsers.machineData

export const editSaveBtnLock = (state: RootState) =>
  state.machineUsers.editSaveBtnLock
