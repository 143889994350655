import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { Color } from '@material-ui/lab/Alert'

export interface SnackbarAlertProps {
  message: string | null | undefined
  open: boolean
  color: Color
  handleClose: () => void
}

const SnackbarAlert: React.FunctionComponent<SnackbarAlertProps> = (
  props: SnackbarAlertProps
) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={props.handleClose}
    >
      <MuiAlert
        onClose={props.handleClose}
        severity={props.color}
        color={props.color}
      >
        {props.message}
      </MuiAlert>
    </Snackbar>
  )
}

export default SnackbarAlert
