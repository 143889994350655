import dashboard from './dashboard'
import warranties from './warranties'
import organizations from './organizations'
import users from './users'
import machineUsers from './machineUsers'
import serviceRequests from './serviceRequests'
import catalogAndInstructions from './catalogAndInstructions'
import reports from './reports'
import alerts from './alerts'
import announcements from './announcements'

export const routes = [
  // Add routes below
  ...dashboard.routes,
  ...organizations.routes,
  ...users.routes,
  ...warranties.routes,
  ...machineUsers.routes,
  ...serviceRequests.routes,
  ...catalogAndInstructions.routes,
  ...reports.routes,
  ...alerts.routes,
  ...announcements.routes,
]

export const reducers = {
  // Add reducers below
  [dashboard.name]: dashboard.reducer,
  [warranties.name]: warranties.reducer,
  [organizations.name]: organizations.reducer,
  [serviceRequests.name]: serviceRequests.reducer,
  [users.name]: users.reducer,
  [machineUsers.name]: machineUsers.reducer,
  [catalogAndInstructions.name]: catalogAndInstructions.reducer,
  [reports.name]: reports.reducer,
  [alerts.name]: alerts.reducer,
  [announcements.name]: announcements.reducer,
}
