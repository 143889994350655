import { createReducer } from '@reduxjs/toolkit'
import {
  Country,
  LoadingStatus,
  MachineUser,
  Organization,
  VerifySerialNumberResponse,
  MachineDataResponse,
  MachineServiceRequest,
  PaginationOptions,
  WarrantiesSortOrder,
  Machine,
  WarrantiesListFilters,
  WarrantyFilterDateType,
} from 'shared/types'
import {
  getCountries,
  getOrganizationWithChildren,
  getOrganizations,
  getMachineUsersWithChildren,
  verifySerialNumber,
  verifySerialNumberForWarrantyRegistration,
  getMachineData,
  getCounties,
  getVoivodeships,
  createWarranty,
  resetIsCreated,
  getWarrantyPdfFile,
  getMachineServiceRequests,
  setIsWarrantyEdit,
  resetIsEdit,
  editWarranty,
  setPage,
  setPageSize,
  setOrder,
  getMachinesWithWarranties,
  setMachineCodeFilter,
  setDateType,
  setDateFrom,
  setDateTo,
  setSerialNumber,
  setDealerId,
  resetFilters,
  setUserExists,
  resetMachineData,
  setMachineTypeFilter,
  setEditSaveBtnLock,
  resetAllState,
  setCountries,
  setVoivodeship,
  setCounty,
  toggleFiltersVisibility,
} from './actions'
import { Warranty } from 'shared/types'
import {
  getRegisteredWarranties,
  closeDetailsDialog,
  goToEditMachine,
  openDetailsDialog,
  resetDialog,
  handleSelectedWarranty,
  editMachine,
} from './actions'

interface State {
  loading: LoadingStatus
  error?: string | null
  organizationsWithChildren: Organization[]
  organizations: Organization[]
  countries: Country[]
  machineUsers: MachineUser[]
  verifySerialNumber: VerifySerialNumberResponse | null
  machineData: MachineDataResponse[] | null
  counties: string[]
  voivodeships: string[]
  registeredWarrantiesList: Warranty[]
  selectedWarranty: Warranty | undefined
  isMachineDetailsDialogOpen: boolean
  isUserEditing: boolean
  machineServiceRequests: MachineServiceRequest[]
  isWarrantyCreated: boolean
  pdfFile: Blob | null
  isWarrantyEdit: boolean
  paginationOptions: PaginationOptions
  order: WarrantiesSortOrder
  machinesWithWarranties: Machine[]
  machinesFetching: boolean
  filters: WarrantiesListFilters
  areFiltersVisible: boolean
  machineUserExists: boolean
  editSaveBtnLock: boolean
}

const initialState: State = {
  loading: LoadingStatus.Idle,
  error: null,
  organizationsWithChildren: [],
  organizations: [],
  countries: [],
  machineUsers: [],
  verifySerialNumber: null,
  machineData: null,
  counties: [],
  voivodeships: [],
  registeredWarrantiesList: [],
  selectedWarranty: undefined,
  isMachineDetailsDialogOpen: false,
  isUserEditing: false,
  machineServiceRequests: [],
  isWarrantyCreated: false,
  pdfFile: null,
  isWarrantyEdit: false,
  paginationOptions: {
    totalPages: 0,
    current: 1,
    pageSize: 25,
  },
  order: WarrantiesSortOrder.Default,
  machinesWithWarranties: [],
  machinesFetching: false,
  filters: {
    machineCode: '',
    dateType: WarrantyFilterDateType.SaleDate,
    dateFrom: undefined,
    dateTo: undefined,
    machineType: '',
    serialNumber: undefined,
    dealerId: undefined,
    countries: [],
    county: undefined,
    voivodeship: undefined,
  },
  areFiltersVisible: true,
  machineUserExists: false,
  editSaveBtnLock: false,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getOrganizationWithChildren.pending, state => {
      state.organizationsWithChildren = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getOrganizationWithChildren.fulfilled, (state, action) => {
      state.organizationsWithChildren = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getOrganizationWithChildren.rejected, (state, action) => {
      state.organizationsWithChildren = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getOrganizations.pending, state => {
      state.organizations = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getOrganizations.fulfilled, (state, action) => {
      state.organizations = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getOrganizations.rejected, (state, action) => {
      state.organizations = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getCountries.pending, state => {
      state.countries = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getCountries.rejected, (state, action) => {
      state.countries = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })

    .addCase(getMachineUsersWithChildren.pending, state => {
      state.machineUsers = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getMachineUsersWithChildren.fulfilled, (state, action) => {
      state.machineUsers = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getMachineUsersWithChildren.rejected, (state, action) => {
      state.machineUsers = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(verifySerialNumber.pending, state => {
      state.verifySerialNumber = null
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(verifySerialNumber.fulfilled, (state, action) => {
      state.verifySerialNumber = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(verifySerialNumber.rejected, (state, action) => {
      state.verifySerialNumber = null
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(verifySerialNumberForWarrantyRegistration.pending, state => {
      state.verifySerialNumber = null
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(
      verifySerialNumberForWarrantyRegistration.fulfilled,
      (state, action) => {
        state.verifySerialNumber = action.payload
        state.loading = LoadingStatus.Succeeded
        state.error = null
      }
    )
    .addCase(
      verifySerialNumberForWarrantyRegistration.rejected,
      (state, action) => {
        state.verifySerialNumber = null
        state.loading = LoadingStatus.Failed
        state.error = action.error.message
      }
    )
    .addCase(getMachineData.pending, state => {
      state.machineData = null
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getMachineData.fulfilled, (state, action) => {
      state.machineData = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getMachineData.rejected, (state, action) => {
      state.machineData = null
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getCounties.pending, state => {
      state.counties = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getCounties.fulfilled, (state, action) => {
      state.counties = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getCounties.rejected, (state, action) => {
      state.counties = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getVoivodeships.pending, state => {
      state.voivodeships = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getVoivodeships.fulfilled, (state, action) => {
      state.voivodeships = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getVoivodeships.rejected, (state, action) => {
      state.voivodeships = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(createWarranty.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(createWarranty.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.error = null
      state.isWarrantyCreated = true
      state.pdfFile = null
    })
    .addCase(createWarranty.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getRegisteredWarranties.pending, state => {
      state.registeredWarrantiesList = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getRegisteredWarranties.fulfilled, (state, action) => {
      state.registeredWarrantiesList = action.payload.list
      state.paginationOptions = {
        ...state.paginationOptions,
        totalPages: action.payload.totalPages,
      }
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getRegisteredWarranties.rejected, (state, action) => {
      state.registeredWarrantiesList = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(handleSelectedWarranty, (state, action) => {
      state.selectedWarranty = action.payload
    })
    .addCase(openDetailsDialog, state => {
      state.isMachineDetailsDialogOpen = true
    })
    .addCase(closeDetailsDialog, state => {
      state.isMachineDetailsDialogOpen = false
      state.isUserEditing = false
    })
    .addCase(resetDialog, state => {
      state.isMachineDetailsDialogOpen = false
      state.selectedWarranty = undefined
      state.isUserEditing = false
    })
    .addCase(goToEditMachine, state => {
      state.isUserEditing = true
    })
    .addCase(getMachineServiceRequests.pending, state => {
      state.machineServiceRequests = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getMachineServiceRequests.fulfilled, (state, action) => {
      state.machineServiceRequests = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getMachineServiceRequests.rejected, (state, action) => {
      state.machineServiceRequests = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getWarrantyPdfFile.pending, state => {
      state.pdfFile = null
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getWarrantyPdfFile.fulfilled, (state, action) => {
      state.pdfFile = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getWarrantyPdfFile.rejected, (state, action) => {
      state.pdfFile = null
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(resetIsCreated, state => {
      state.isWarrantyCreated = false
      state.pdfFile = null
    })
    .addCase(setIsWarrantyEdit, state => {
      state.isWarrantyEdit = true
    })
    .addCase(editMachine.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(editMachine.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.isUserEditing = false
      state.selectedWarranty = undefined
      state.isMachineDetailsDialogOpen = false
      state.error = null
    })
    .addCase(editMachine.rejected, (state, action) => {
      if (action.payload === 400) state.loading = LoadingStatus.Succeeded
      else {
        state.loading = LoadingStatus.Failed
        state.error = action.error.message
      }
    })
    .addCase(editWarranty.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(editWarranty.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.isWarrantyEdit = false
      state.error = null
      state.pdfFile = null
    })
    .addCase(editWarranty.rejected, (state, action) => {
      if (action.payload === 400) state.loading = LoadingStatus.Succeeded
      else {
        state.loading = LoadingStatus.Failed
        state.error = action.error.message
      }
    })
    .addCase(resetIsEdit, state => {
      state.selectedWarranty = undefined
      state.isWarrantyEdit = false
      state.pdfFile = null
    })
    .addCase(setPage, (state, action) => {
      state.paginationOptions = {
        ...state.paginationOptions,
        current: action.payload,
      }
    })
    .addCase(setPageSize, (state, action) => {
      state.paginationOptions = {
        ...state.paginationOptions,
        pageSize: action.payload,
        current: 1,
      }
    })
    .addCase(setOrder, (state, action) => {
      state.order = action.payload
    })
    .addCase(getMachinesWithWarranties.pending, state => {
      state.machinesWithWarranties = []
      state.error = null
      state.machinesFetching = true
    })
    .addCase(getMachinesWithWarranties.fulfilled, (state, action) => {
      state.machinesWithWarranties = action.payload
      state.machinesFetching = false
      state.error = null
    })
    .addCase(getMachinesWithWarranties.rejected, (state, action) => {
      state.machinesFetching = false
      state.error = action.error.message
    })
    .addCase(setMachineCodeFilter, (state, action) => {
      state.filters = { ...state.filters, machineCode: action.payload }
      state.paginationOptions = {
        ...state.paginationOptions,
        current: 1,
      }
    })
    .addCase(setMachineTypeFilter, (state, action) => {
      state.filters = { ...state.filters, machineType: action.payload }
      state.paginationOptions = {
        ...state.paginationOptions,
        current: 1,
      }
    })
    .addCase(setDateType, (state, action) => {
      state.filters = { ...state.filters, dateType: action.payload }
      state.paginationOptions = {
        ...state.paginationOptions,
        current: 1,
      }
    })
    .addCase(setDateFrom, (state, action) => {
      state.filters = { ...state.filters, dateFrom: action.payload }
      state.paginationOptions = {
        ...state.paginationOptions,
        current: 1,
      }
    })
    .addCase(setDateTo, (state, action) => {
      state.filters = { ...state.filters, dateTo: action.payload }
      state.paginationOptions = {
        ...state.paginationOptions,
        current: 1,
      }
    })
    .addCase(setSerialNumber, (state, action) => {
      state.filters = { ...state.filters, serialNumber: action.payload }
      state.paginationOptions = {
        ...state.paginationOptions,
        current: 1,
      }
    })
    .addCase(setDealerId, (state, action) => {
      state.filters = { ...state.filters, dealerId: action.payload }
      state.paginationOptions = {
        ...state.paginationOptions,
        current: 1,
      }
    })
    .addCase(setUserExists, (state, action) => {
      state.machineUserExists = action.payload
    })
    .addCase(resetFilters, state => {
      state.paginationOptions = { ...initialState.paginationOptions }
      state.order = initialState.order
      state.filters = { ...initialState.filters }
    })
    .addCase(resetMachineData, state => {
      state.machineData = null
      state.verifySerialNumber = null
    })
    .addCase(setEditSaveBtnLock, (state, action) => {
      state.editSaveBtnLock = action.payload
    })
    .addCase(resetAllState, (state, action) => {
      return initialState
    })
    .addCase(setCountries, (state, action) => {
      state.filters = { ...state.filters, countries: action.payload }
    })
    .addCase(setVoivodeship, (state, action) => {
      state.filters = { ...state.filters, voivodeship: action.payload }
    })
    .addCase(setCounty, (state, action) => {
      state.filters = { ...state.filters, county: action.payload }
    })
    .addCase(toggleFiltersVisibility, state => {
      state.areFiltersVisible = !state.areFiltersVisible
    })
)
