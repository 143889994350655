import { createReducer } from '@reduxjs/toolkit'
import { Alert, Country, LoadingStatus } from 'shared/types'
import {
  deleteAlerts,
  getAlerts,
  getCountries,
  resetState,
  setSelectedAlertsId,
} from './actions'

export interface State {
  loading: LoadingStatus
  error?: string | null
  alerts: Alert[]
  selectedAlerts: number[]
  countries: Country[]
}

const initialState: State = {
  loading: LoadingStatus.Idle,
  error: null,
  alerts: [],
  selectedAlerts: [],
  countries: [],
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getAlerts.pending, state => {
      state.error = null
      state.loading = LoadingStatus.Pending
    })
    .addCase(getAlerts.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.error = null
      state.alerts = action.payload
    })
    .addCase(getAlerts.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
      state.alerts = []
    })
    .addCase(deleteAlerts.pending, state => {
      state.error = null
      state.loading = LoadingStatus.Pending
    })
    .addCase(deleteAlerts.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(deleteAlerts.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(setSelectedAlertsId, (state, action) => {
      state.selectedAlerts = action.payload
    })
    .addCase(resetState, () => {
      return initialState
    })
    .addCase(getCountries.pending, state => {
      state.countries = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getCountries.rejected, (state, action) => {
      state.countries = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
)
