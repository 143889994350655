import {
  Drawer,
  DrawerProps,
  styled,
  Theme,
  makeStyles,
} from '@material-ui/core'

export const CLOSED_DRAWER_WIDTH = 56
export const OPEN_DRAWER_WIDTH = 240

export const useStyles = makeStyles(theme => ({
  listItem: {
    fontSize: '11px !important',
  },
}))

export const StyledDrawer = styled(Drawer)<Theme, DrawerProps>(
  ({ theme, open }) => {
    const width = open ? OPEN_DRAWER_WIDTH : CLOSED_DRAWER_WIDTH
    return {
      width,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      '& .MuiDrawer-paper': {
        width,
        overflowX: 'hidden',
        color: theme.palette.common.white,
        background: theme.palette.secondary.dark,
        borderRightColor: theme.palette.secondary.light,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    }
  }
)
