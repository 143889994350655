import { Redirect, Route } from 'react-router-dom'
import { paths } from 'config'
import { useAuth } from 'shared/hooks'
import { ModuleRoute } from 'shared/types'
import { Loadable } from 'shared/components'

const PrivateRoute = (props: ModuleRoute) => {
  const { isAuthenticated, tokenData } = useAuth()
  const neededRoles = props.needRole
  const userName = props.needUserName
  if (isAuthenticated) {
    if (neededRoles === undefined && !userName) return <Route {...props} />
    else if (
      (neededRoles &&
        neededRoles?.find(item => item === tokenData?.role) === undefined) ||
      (userName && userName !== tokenData?.identifier)
    )
      return (
        <Route
          path={props.path}
          exact={true}
          component={Loadable({
            component: () => import('modules/dashboard/pages/AccessDenied'),
          })}
        />
      )
    else return <Route {...props} />
  } else return <Redirect push to={paths.login} />
}

export default PrivateRoute
