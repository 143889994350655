import {
  AppBarProps,
  IconButton,
  Toolbar,
  Box,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core'
import {
  ExitToApp,
  Menu,
  NotificationsOutlined,
  NotificationsActive,
} from '@material-ui/icons'
import { useAuth, useLanguageChange } from 'shared/hooks'
import { AppBar, useStyles } from './Topbar.style'
import { Redirect, useHistory, useLocation } from 'react-router'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'shared/store'
import { paths } from 'config'

export interface TopbarProps extends Partial<AppBarProps> {
  hidden?: boolean
  shifted: boolean
  onMenuButtonClick: () => void
  menuButtonHidden?: boolean
}

const Topbar = ({
  hidden,
  shifted,
  onMenuButtonClick,
  menuButtonHidden,
  ...props
}: TopbarProps) => {
  const { isAuthenticated, logout, tokenData } = useAuth()
  const [changeLanguage, currentLanguage] = useLanguageChange()
  const dispatch = useDispatch()
  const haveAlerts = useSelector(selectors.alerts.getHasNewAlerts)
  const location = useLocation()
  const history = useHistory()

  const styles = useStyles()

  useEffect(() => {
    if (isAuthenticated && !haveAlerts)
      dispatch(actions.alerts.checkIfUserHaveNewAlerts())
  }, [location.pathname])

  const [alertRedirect, setAlertRedirect] = useState(false)

  useEffect(() => {
    if (alertRedirect) {
      setAlertRedirect(false)
      history.push(paths.alerts)
    }
  }, [alertRedirect])
  if (hidden) return null

  return (
    <AppBar
      position="fixed"
      shifted={shifted}
      color="default"
      elevation={0}
      {...props}
    >
      <Toolbar>
        {!shifted && !menuButtonHidden && (
          <IconButton id="showMenuBtn" edge="start" onClick={onMenuButtonClick}>
            <Menu />
          </IconButton>
        )}
        <Grid container justify="flex-end" alignItems="center">
          <Box paddingX={2}>
            <IconButton
              id="alertsBtn"
              edge="start"
              color={haveAlerts ? 'primary' : 'secondary'}
              onClick={() => setAlertRedirect(true)}
            >
              {haveAlerts ? <NotificationsActive /> : <NotificationsOutlined />}
            </IconButton>
          </Box>
          {tokenData !== undefined && (
            <Box paddingRight={2} className={styles.userBox}>
              {tokenData.name} {tokenData.surname}
            </Box>
          )}
          <Box width={50} marginX={2}>
            <Select
              value={currentLanguage}
              className={styles.select}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                changeLanguage(e.target.value as string)
              }
            >
              <MenuItem value={'pl'}>PL</MenuItem>
              <MenuItem value={'eng'}>ENG</MenuItem>
              <MenuItem value={'rus'}>RUS</MenuItem>
              <MenuItem value={'fr'}>FR</MenuItem>
              <MenuItem value={'es'}>ES</MenuItem>
              <MenuItem value={'de'}>DE</MenuItem>
            </Select>
          </Box>
          {isAuthenticated && (
            <IconButton
              id="logoutBtn"
              edge="start"
              color="inherit"
              onClick={logout}
            >
              <ExitToApp />
            </IconButton>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Topbar
