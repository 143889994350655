import { combineReducers } from 'redux'
import auth from './auth'
import alerts from './alerts'

export const actions = {
  auth: auth.actions,
  alerts: alerts.actions,
}

export const selectors = {
  auth: auth.selectors,
  alerts: alerts.selectors,
}

export const reducer = combineReducers({
  auth: auth.reducer,
  alerts: alerts.reducer,
})
