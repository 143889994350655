import { useState } from 'react'

export const useAlert = (
  initialState: boolean = false
): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(initialState)
  const openAlert = () => setIsOpen(true)
  const closeAlert = () => setIsOpen(false)

  return [isOpen, openAlert, closeAlert]
}
