import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { Api, MachineUserEdit } from 'shared/services'
import { EditMachine, MachineUser } from 'shared/types'

const api = new Api()

export const getMachineUsers = createAsyncThunk(
  'machineUsers/getMachineUsers',
  async () => {
    const response = await api.getMachineUsers()
    return response.data
  }
)

export const getOrganizations = createAsyncThunk(
  'machineUsers/getDealers',
  async () => {
    const response = await api.getOrganizationsWithParent()
    return response.data
  }
)

export const getCountries = createAsyncThunk(
  'machineUsers/getCountries',
  async () => {
    const response = await api.getCountries()
    return response.data
  }
)

export const editMachineUser = createAsyncThunk(
  'machineUsers/editMachineUser',
  async (
    payload: { id: number | undefined; user: MachineUserEdit },
    { dispatch, rejectWithValue }
  ) => {
    const { id, user } = payload
    try {
      const response = await api.editMachineUser(id, user)
      dispatch(getMachineUsers())
      return response.data
    } catch (err) {
      if (!err.response) throw err
      if (err.response.status === 400) {
        if (err.response.data === 'already exists') {
          dispatch(acronymExists())
          return rejectWithValue(err.response.status)
        }
      }
      throw err
    }
  }
)

export const selectUserToSeeDetails = createAction<MachineUser>(
  'machineUsers/selectToSeeDetails'
)

export const openDetailsDialog = createAction('machineUsers/openDetailsDialog')

export const closeDetailsDialog = createAction(
  'machineUsers/closeDetailsDialog'
)

export const resetDialog = createAction('machineUsers/resetState')

export const goToEditMachineUser = createAction(
  'machineUsers/goToEditMachineUser'
)

export const acronymExists = createAction('machineUsers/acronymExists')

export const resetaAcronymExists = createAction('machineUsers/acronymExists')

export const setIsMachineModalOpen = createAction<boolean>(
  'machineUsers/setIsMachineModalOpen'
)

export const setEditSaveBtnLock = createAction<boolean>(
  'machineUsers/setEditSaveBtnLock'
)

export const setIsMachineEdit = createAction<boolean>(
  'machineUsers/setIsMachineEdit'
)

export const getMachineDetails = createAsyncThunk(
  'machineUsers/getMachineDetails',
  async (id: number) => {
    const response = await api.getMachineDetails(id)
    return response.data
  }
)

export const editMachine = createAsyncThunk(
  'machineUsers/editMachine',
  async (
    payload: {
      machineId: number | undefined
      machine: EditMachine
    },
    { dispatch, rejectWithValue }
  ) => {
    const { machineId, machine } = payload

    const response = await api.editMachineWithMachineId(machineId, machine)

    return response.data
  }
)

export const getMachineData = createAsyncThunk(
  'machineUsers/getMachineData',
  async (serialNumber: string) => {
    const response = await api.getMachineData(serialNumber)
    return response.data
  }
)

export const verifySerialNumber = createAsyncThunk(
  'warranties/verifySerialNumber',
  async (payload: { serialNumber: string; machineCode: string }) => {
    const { serialNumber, machineCode } = payload
    const response = await api.verifySerialNumber(serialNumber, machineCode)
    return response.data
  }
)
