import type { RootState } from 'app'

export const getOrganizationList = (state: RootState) =>
  state.organizations.organizationList

export const getCountries = (state: RootState) => state.organizations.countries

export const getCountriesArray = (state: RootState) =>
  state.organizations.countriesArray

export const isLoading = (state: RootState) => state.organizations.loading

export const getError = (state: RootState) => state.organizations.error

export const getSamaszTraders = (state: RootState) =>
  state.organizations.traders

export const isCreated = (state: RootState) => state.organizations.isCreated

export const isEdited = (state: RootState) => state.organizations.isEdited

export const nipExists = (state: RootState) => state.organizations.nipExists

export const acronymExists = (state: RootState) =>
  state.organizations.acronymExists

export const nameExists = (state: RootState) => state.organizations.nameExists
