import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: '/alerts',
    exact: true,
    component: Loadable({
      component: () => import('./pages/Alerts'),
    }),
  },
]

export default routes
