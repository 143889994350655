import { Loadable } from 'shared/components'
import { ModuleRoute, UserRoles } from 'shared/types'
import BarChartIcon from '@material-ui/icons/BarChart'

const routes: ModuleRoute[] = [
  {
    path: '/reports',
    exact: true,
    layoutSettings: {
      label: 'navigation.reports',
      icon: BarChartIcon,
    },
    component: Loadable({
      component: () => import('./pages/Reports'),
    }),
    needRole: [UserRoles.MainAdministrator, UserRoles.SamaszEmployee],
  },
]

export default routes
