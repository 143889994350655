import PersonIcon from '@material-ui/icons/Person'
import { Loadable } from 'shared/components'
import { ModuleRoute, UserRoles } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: '/users',
    exact: true,
    layoutSettings: {
      icon: PersonIcon,
      label: 'navigation.users',
      needSpace: true,
    },
    component: Loadable({
      component: () => import('./pages/UserList'),
    }),
  },
  {
    path: '/addUser',
    exact: true,
    component: Loadable({
      component: () => import('../users/pages/AddUser'),
    }),
    needRole: [
      UserRoles.D1Administrator,
      UserRoles.MainAdministrator,
      UserRoles.SamaszEmployee,
      UserRoles.D2Administrator,
      UserRoles.D3Administrator,
      UserRoles.D3_1Administrator,
    ],
  },
  {
    path: '/editUser',
    exact: true,
    component: Loadable({
      component: () => import('../users/pages/EditUser'),
    }),
    needRole: [
      UserRoles.D1Administrator,
      UserRoles.MainAdministrator,
      UserRoles.SamaszEmployee,
      UserRoles.D2Administrator,
      UserRoles.D3Administrator,
      UserRoles.D3_1Administrator,
    ],
  },
  {
    path: '/activate/',
    exact: true,
    component: Loadable({
      component: () => import('../users/pages/ActivateUser'),
    }),
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },

    public: true,
  },
  {
    path: '/newPassword/',
    exact: true,
    component: Loadable({
      component: () => import('../users/pages/NewPassword'),
    }),
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    public: true,
  },
]

export default routes
