import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Api } from 'shared/services'
import { BlobListItem } from 'shared/types'
import { SubDirectoryProps } from '../components/InstructionsTable/InstructionsTable.utils'

const api = new Api()

export const getInstructions = createAsyncThunk(
  'instructions/getInstructionsList',
  async () => {
    const response = await api.getInstructions('')
    return response.data
  }
)

export const getInstruction = createAsyncThunk(
  'instructions/getInstruction',
  async (id: number) => {
    const response = await api.getInstructionById(id)
    return response.data
  }
)

export const getSubDirectories = createAsyncThunk(
  'instructions/getSubDirectories',
  async (inputData: SubDirectoryProps) => {
    const response = await api.getInstructions(inputData.uniqueId)
    return {
      response: response.data,
      prefix: inputData.prefix,
      path: inputData.fileDirRef,
    }
  }
)

export const resetLink = createAction('instructions/resetLink')

export const closeDirectory = createAction<BlobListItem>(
  'instructions/closeDirectory'
)

export const openDirectory = createAction<BlobListItem>(
  'instructions/openDirectory'
)
