import {
  styled,
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
  Theme,
} from '@material-ui/core'

interface ButtonProps extends BaseButtonProps {
  square?: boolean
}

const Button = styled(BaseButton)<Theme, ButtonProps>(({ square }) =>
  square ? { borderRadius: 0 } : {}
)

export default Button
