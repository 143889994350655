import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Api } from 'shared/services'

const api = new Api()

export const checkIfUserHaveNewAlerts = createAsyncThunk(
  'alerts/checkIfUserHaveNewAlerts',
  async () => {
    const response = await api.checkIfUserHaveNewAlerts()
    return response.data
  }
)

export const reset = createAction('alerts/reset')
