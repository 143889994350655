import { createReducer } from '@reduxjs/toolkit'
import { LoadingStatus, OrganizationStatus } from 'shared/types'
import {
  getCountries,
  getOrganizations,
  lockOrganization,
  unlockOrganization,
  deleteOrganization,
  getSamaszTraders,
  createOrganization,
  nameExists,
  nipExists,
  acronymExists,
  resetNameExists,
  resetAcronymExists,
  resetState,
  resetNipExists,
  editOrganization,
} from './actions'
import { Organization, Country, User } from 'shared/types/interfaces'
import { countries } from 'shared/dictionaries/CountriesDictionary'

export interface State {
  organizationList: Organization[]
  loading: LoadingStatus
  error?: string | null
  countries: { [id: number]: Country }
  countriesArray: Country[]
  traders: User[]
  isCreated: boolean
  nameExists: boolean
  acronymExists: boolean
  nipExists: boolean
  isEdited: boolean
}

const initialState: State = {
  organizationList: [],
  loading: LoadingStatus.Idle,
  error: null,
  countries: {},
  countriesArray: [],
  traders: [],
  isCreated: false,
  nameExists: false,
  acronymExists: false,
  nipExists: false,
  isEdited: false,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getOrganizations.pending, state => {
      state.organizationList = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getOrganizations.fulfilled, (state, action) => {
      state.organizationList = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getOrganizations.rejected, (state, action) => {
      state.organizationList = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getCountries.pending, state => {
      state.countries = {}
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state.countries = countries(action.payload)
      state.countriesArray = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getCountries.rejected, (state, action) => {
      state.countries = {}
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(lockOrganization.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(lockOrganization.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.organizationList = action.payload
      state.error = null
    })
    .addCase(lockOrganization.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(unlockOrganization.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(unlockOrganization.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.organizationList = action.payload
      state.error = null
    })
    .addCase(unlockOrganization.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(deleteOrganization.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(deleteOrganization.fulfilled, (state, action) => {
      state.organizationList = state.organizationList.filter(
        organization => organization.id !== action.payload
      )
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(deleteOrganization.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getSamaszTraders.pending, state => {
      state.traders = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getSamaszTraders.fulfilled, (state, action) => {
      state.traders = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getSamaszTraders.rejected, (state, action) => {
      state.traders = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(createOrganization.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(createOrganization.fulfilled, (state, action) => {
      state.isCreated = true
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(createOrganization.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(editOrganization.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(editOrganization.fulfilled, (state, action) => {
      state.isEdited = true
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(editOrganization.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(resetState, state => {
      state.isCreated = false
      state.nameExists = false
      state.acronymExists = false
      state.nipExists = false
      state.isEdited = false
      state.error = initialState.error
    })
    .addCase(nameExists, state => {
      state.nameExists = true
    })
    .addCase(nipExists, state => {
      state.nipExists = true
    })
    .addCase(acronymExists, state => {
      state.acronymExists = true
    })
    .addCase(resetNameExists, state => {
      state.nameExists = false
    })
    .addCase(resetAcronymExists, state => {
      state.acronymExists = false
    })
    .addCase(resetNipExists, state => {
      state.nipExists = false
    })
)
