import { styled } from '@material-ui/core'
import { TOOLBAR_HEIGHT } from './Topbar'

export const Container = styled('div')({
  display: 'flex',
  minHeight: `calc(100vh - ${TOOLBAR_HEIGHT}px)`,
  maxWidth: '100%',
})

export const Content = styled('main')({
  flexGrow: 1,
  minHeight: '100%',
})
