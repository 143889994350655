import { createReducer } from '@reduxjs/toolkit'
import { LoadingStatus } from 'shared/types'
import { checkIfUserHaveNewAlerts, reset } from './actions'

interface State {
  hasNewAlerts: boolean
  loading: LoadingStatus
  error?: string | null
}

const initialState: State = {
  hasNewAlerts: false,
  loading: LoadingStatus.Idle,
  error: null,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(checkIfUserHaveNewAlerts.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(checkIfUserHaveNewAlerts.fulfilled, (state, action) => {
      state.hasNewAlerts = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(checkIfUserHaveNewAlerts.rejected, (state, action) => {
      state.hasNewAlerts = false
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(reset, state => {
      state.hasNewAlerts = false
    })
)
