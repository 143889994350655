import { ComponentsProps } from '@material-ui/core/styles/props'

const props: ComponentsProps = {
  MuiTextField: {
    fullWidth: true,
    variant: 'outlined',
  },
  MuiInput: {
    fullWidth: true,
  },
  MuiFormControl: {
    fullWidth: true,
  },
  MuiButton: {
    disableElevation: true,
    fullWidth: true,
  },
  MuiSelect: {
    MenuProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      PaperProps: {
        style: {
          maxHeight: 300,
        },
      },
      getContentAnchorEl: null,
    },
  },
}

export default props
