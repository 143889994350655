import { Provider } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { Layout, PrivateRoute } from 'shared/components'
import { reducer as common } from 'shared/store'
import { paths, theme } from 'config'
import { routes, reducers } from 'modules'

export const store = configureStore({
  reducer: combineReducers({ ...reducers, common }),
})

export type RootState = ReturnType<typeof store.getState>

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Layout routes={routes}>
          <Switch>
            {routes.map(route =>
              route.public ? (
                <Route key={`publicRoute-${route.path}`} {...route} />
              ) : (
                <PrivateRoute key={`privateRoute-${route.path}`} {...route} />
              )
            )}
            <Route component={() => <Redirect push to={paths.dashboard} />} />
          </Switch>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
)

export default App
