const throwError = (message: string) => {
  throw new Error(message)
}

const config = {
  storageTokenKey: 'authToken',
  tokenExpiration: 'tokenExpiration',
  apiUrl:
    process.env.REACT_APP_API_URL ||
    throwError('Missing API_URL env variable.'),
  announcementsUserName: process.env.REACT_APP_ANNOUNCEMENTS_USER_NAME,
}

export default config

export { default as i18n } from './i18n'
export { default as consoleFilter } from './consoleFilter'
export { default as theme } from './theme'
export { default as paths } from './paths'
