import { createReducer } from '@reduxjs/toolkit'
import {
  LoadingStatus,
  Country,
  User,
  Organization,
  UserStatus,
} from 'shared/types'
import {
  getUsers,
  getOrganizations,
  getCountries,
  getIdentifier,
  createUser,
  resetIdentifier,
  resetIsCreated,
  editUser,
  resetIsEdited,
  lockUser,
  unLockUser,
  deleteUser,
  activateUser,
  resetIsActivated,
  changePassword,
  editUserWithResendMail,
} from './actions'
import { countries } from 'shared/dictionaries/CountriesDictionary'

export interface State {
  userList: User[]
  loading: LoadingStatus
  error?: string | null
  organizationList: Organization[]
  countries: { [id: number]: Country }
  identifier: string
  isUserCreated: boolean
  isUserEdited: boolean
  isUserActivated: boolean
  isPasswordChanged: boolean
}

const initialState: State = {
  userList: [],
  loading: LoadingStatus.Idle,
  error: null,
  organizationList: [],
  countries: {},
  identifier: '',
  isUserCreated: false,
  isUserEdited: false,
  isUserActivated: false,
  isPasswordChanged: false,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getUsers.pending, state => {
      state.userList = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getUsers.fulfilled, (state, action) => {
      state.userList = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getUsers.rejected, (state, action) => {
      state.userList = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getOrganizations.pending, state => {
      state.organizationList = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getOrganizations.fulfilled, (state, action) => {
      state.organizationList = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getOrganizations.rejected, (state, action) => {
      state.organizationList = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getCountries.pending, state => {
      state.countries = {}
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state.countries = countries(action.payload)
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getCountries.rejected, (state, action) => {
      state.countries = {}
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getIdentifier.pending, state => {
      state.identifier = ''
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getIdentifier.fulfilled, (state, action) => {
      state.identifier = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getIdentifier.rejected, (state, action) => {
      state.identifier = ''
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(createUser.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(createUser.fulfilled, (state, action) => {
      state.isUserCreated = true
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(createUser.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(editUser.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(editUser.fulfilled, (state, action) => {
      state.isUserEdited = true
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(editUser.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(editUserWithResendMail.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(editUserWithResendMail.fulfilled, (state, action) => {
      state.isUserEdited = true
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(editUserWithResendMail.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(lockUser.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(lockUser.fulfilled, (state, action) => {
      state.userList = state.userList.map(item => {
        if (item.id !== action.payload) return item
        else return { ...item, status: UserStatus.Blocked }
      })
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(lockUser.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(unLockUser.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(unLockUser.fulfilled, (state, action) => {
      state.userList = state.userList.map(item => {
        if (item.id !== action.payload) return item
        else return { ...item, status: UserStatus.Active }
      })
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(unLockUser.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(deleteUser.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(deleteUser.fulfilled, (state, action) => {
      state.userList = state.userList.filter(item => item.id !== action.payload)
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(deleteUser.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(activateUser.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(activateUser.fulfilled, (state, action) => {
      state.isUserActivated = true
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(activateUser.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.payload as string
    })
    .addCase(changePassword.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(changePassword.fulfilled, (state, action) => {
      state.isPasswordChanged = true
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(changePassword.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.payload as string
    })
    .addCase(resetIdentifier, (state, action) => {
      state.identifier = action.payload
    })
    .addCase(resetIsCreated, state => {
      state.isUserCreated = false
    })
    .addCase(resetIsEdited, state => {
      state.isUserEdited = false
    })
    .addCase(resetIsActivated, state => {
      state.isUserActivated = false
    })
)
