import { useState } from 'react'
import { TextField, TextFieldProps } from 'formik-material-ui'
import { InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonColor: {
      color: theme.palette.primary.main,
    },
  })
)

interface PasswordFieldProps extends TextFieldProps {}

const PasswordField = (props: PasswordFieldProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const styles = useStyles()

  const toggleIsPasswordVisible = () =>
    setIsPasswordVisible(isVisible => !isVisible)

  return (
    <TextField
      type={isPasswordVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              id="showHidePassword"
              onClick={toggleIsPasswordVisible}
              edge="end"
            >
              {isPasswordVisible ? (
                <VisibilityOff className={styles.buttonColor} />
              ) : (
                <Visibility className={styles.buttonColor} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}

export default PasswordField
