const paths = {
  dashboard: '/',
  login: '/login',
  organizations: '/organizations',
  addOrganization: '/addOrganization',
  editOrganization: '/editOrganization',
  userList: '/users',
  addUser: '/addUser',
  editUser: '/editUser',
  activate: '/activate',
  newPassword: '/newPassword',
  resetPassword: '/resetPassword',
  accessDenied: '/accessDenied',
  addServiceRequest: '/addServiceRequest',
  warrantyRegistration: '/register-warranty',
  catalogAndInstructions: '/catalogAndInstructions',
  editWarranty: '/edit-warranty',
  reports: '/reports',
  alerts: '/alerts',
  serviceRequestDetails: '/serviceRequestDetails/',
}

export default paths
