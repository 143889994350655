import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import config from 'config'
import Auth from '../Auth'
import {
  LoginPayload,
  UserPayload,
  ActivateUserPayload,
  LoginResponse,
  ChangePasswordPayload,
  MachineUserEdit,
  GetWarrantiesResponse,
  GetWarrantiesParameters,
  InstructionBlob,
  GetServiceRequestsParameters,
  GetServiceRequestsResponse,
  PostalCodeResponse,
  VerifyUserResponse,
  VerifyUserRequest,
  ChangeServiceRequestStatus,
  InstructionFile,
  GetMachineDataRespone,
} from './Api.types'
import {
  Organization,
  Country,
  User,
  MachineUser,
  Machine,
  MachineDataResponse,
  VerifySerialNumberResponse,
  Warranty,
  MachineServiceRequest,
  EditMachine,
  ServiceRequestBase,
  Report,
  ServiceRequestStatus,
  ServiceRequestReasonFormValues,
  ServiceRequestCommentFormValues,
  Deliverers,
  Alert,
  MachineDetails,
  AnnouncementDashboardItem,
  Announcement,
} from '../../types/interfaces'
import { SetPasswordValues } from 'modules/users/components/SetPasswordForm/SetPasswordForm.utils'
import { UserRoles, UserStatus } from 'shared/types'
import { ServiceRequestFormValues } from 'modules/serviceRequests/components/ServiceRequestDetailsForm/ServiceRequestDetailsForm.utils'
import { AnnouncementFormValues } from 'modules/announcements/components/AnnouncementForm/AnnouncementForm.utils'
import { GetAnnouncementsParameters, GetAnnouncementsResponse } from '.'
import queryString from 'query-string'

class Api {
  protected api: AxiosInstance = axios.create({ baseURL: config.apiUrl })

  constructor() {
    this.api.interceptors.request.use(this.authenticate)
    // this.api.interceptors.response(this.refreshToken)
  }

  private authenticate(config: AxiosRequestConfig) {
    const token = Auth.getToken()
    config.headers.Authorization = `Bearer ${token}`
    return config
  }

  public async login(payload: LoginPayload) {
    const { data } = await this.api.post<LoginResponse>(
      '/api/user/login',
      payload
    )
    Auth.setToken(data.token)
    return
  }

  public async getOrganizations() {
    const response = await this.api.get<Organization[]>('/api/Organizations')
    return response
  }

  public async getOrganizationsFaster() {
    const response = await this.api.get<Organization[]>(
      '/api/Organizations/GetOrganizations'
    )
    return response
  }

  public async getOrganizationsWithParent() {
    const response = await this.api.get<Organization[]>(
      '/api/Organizations/GetOrganizationsWithParents'
    )
    return response
  }

  public async getCountries() {
    const response = await this.api.get<Country[]>('/api/Country')
    return response
  }
  public async getUsers() {
    const response = await this.api.get<User[]>('/api/User/GetUsers')
    return response
  }

  public async getUsersWithRole() {
    const response = await this.api.get<User[]>(
      '/api/user/GetUsersWithRole?role=' + UserRoles.SamaszTrader
    )
    return response
  }

  public createOrganization = (values: any) => {
    return this.api.post('/api/organizations', values)
  }

  public editOrganization = (values: any, id: any) => {
    return this.api.put('/api/organizations?id=' + id, values)
  }

  public async deleteOrganization(id: number) {
    const response = await this.api.delete('/api/Organizations?id=' + id)
    return response
  }

  public async getMachineUsers() {
    const response = await this.api.get<MachineUser[]>(
      '/api/MachineUser/GetMachineUsers'
    )
    return response
  }

  public async getMachineUsersWithChildren() {
    const response = await this.api.get<MachineUser[]>(
      '/api/MachineUser/GetMachineUsersWithChildren'
    )
    return response
  }

  public async getIdentifier(surname: string, organizationId: number) {
    const response = await this.api.get<string>(
      '/api/User/GetIdentifier?surname=' +
        surname +
        '&organizationId=' +
        organizationId
    )
    return response
  }

  public async getMachinesWithWarranties() {
    const response = await this.api.get<Machine[]>(
      '/api/Machine/GetMachinesWithWarranties'
    )
    return response
  }

  public async getDeliverers() {
    const response = await this.api.get<Deliverers[]>(
      '/api/ServiceRequest/GetDeliverers'
    )
    return response
  }

  public async getMachinesWithServiceRequests() {
    const response = await this.api.get<Machine[]>(
      '/api/Machine/GetMachinesWithServiceRequests'
    )
    return response
  }

  public async createUser(user: UserPayload) {
    const response = await this.api.post<UserPayload>(
      '/api/User/Register',
      user
    )
    return response
  }

  public async wasEmailUsed(email: string) {
    const response = await this.api.get<boolean>(
      '/api/User/WasEmailUsed?email=' + email
    )
    return response
  }

  public async editUser(user: UserPayload, id: number) {
    const response = await this.api.put('/api/User/EditUser?id=' + id, user)
    return response
  }

  public async editUserAndSendMail(user: UserPayload, id: number) {
    const response = await this.api.put(
      '/api/User/EditUserWithSendMail?id=' + id,
      user
    )
    return response
  }

  public async changeUserStatus(id: number, status: UserStatus) {
    const response = await this.api.put(
      '/api/User/ChangeStatus?id=' + id + '&status=' + status
    )
    return response
  }

  public async activateUser(token: string, values: SetPasswordValues) {
    const data: ActivateUserPayload = {
      token: token,
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
    }
    const response = await this.api.post('/api/User/Activate', data)
    return response
  }

  public async resetPassword(email: string) {
    const response = await this.api.post(
      '/api/User/GetLinkToPasswordChange?email=' + email
    )
    return response
  }

  public async changePassword(token: string, values: SetPasswordValues) {
    const data: ChangePasswordPayload = {
      token: token,
      newPassword: values.password,
      newPasswordConfirmation: values.passwordConfirmation,
    }
    const response = await this.api.post('/api/User/ChangePassword', data)
    return response
  }

  public async editMachineUser(id: number | undefined, user: MachineUserEdit) {
    const response = await this.api.put(
      '/api/MachineUser/EditMachineUser?id=' + id,
      user
    )
    return response
  }

  public async GetOrganizationWithChildren() {
    const response = await this.api.get<Organization[]>(
      '/api/ServiceRequest/GetOrganizationWithChildren'
    )
    return response
  }

  public async verifySerialNumber(serialNumber: string, machineCode: string) {
    const response = await this.api.post<VerifySerialNumberResponse>(
      '/api/Warranties/VerifySerialNumber',
      {
        serialNumber: serialNumber,
        machineCode: machineCode,
      }
    )
    return response
  }

  public async verifySerialNumberForWarrantyRegistration(
    serialNumber: string,
    machineCode: string,
    forceWarrantyRegistration: boolean
  ) {
    const forceWarrantyRegistrationFlag = forceWarrantyRegistration ?? false
    const response = await this.api.post<VerifySerialNumberResponse>(
      '/api/Warranties/VerifySerialNumberForWarrantyRegistration?forceWarrantyRegistration=' +
        forceWarrantyRegistrationFlag,
      {
        serialNumber: serialNumber,
        machineCode: machineCode,
      }
    )
    return response
  }

  public async getEndWarranty(
    serialNumber: string,
    machineCode: string,
    saleDate: Date | null
  ) {
    const response = await this.api.post<string | undefined>(
      '/api/Warranties/GetMachineDataWarrantyEnd',
      {
        serialNumber: serialNumber,
        machineCode: machineCode,
        saleDate: saleDate,
      }
    )
    return response
  }

  public async getMachineData(serialNumber: string) {
    const response = await this.api.get<MachineDataResponse[]>(
      '/api/Warranties/GetMachineData?serialNumber=' + serialNumber
    )
    return response
  }

  public async getCountiesForWarranties(voivodeship?: string) {
    const response =
      voivodeship !== undefined
        ? await this.api.get<string[]>(
            '/api/Machine/GetCountiesForWarranties?voivodeship=' + voivodeship
          )
        : await this.api.get<string[]>('/api/Machine/GetCountiesForWarranties')

    return response
  }

  public async getVoivodeshipsForWarranties(county?: string) {
    const response =
      county !== undefined
        ? await this.api.get<string[]>(
            '/api/Machine/GetGetVoivodeshipsForWarranties?county=' + county
          )
        : await this.api.get<string[]>(
            '/api/Machine/GetGetVoivodeshipsForWarranties'
          )

    return response
  }

  public async createWarranty(
    warranty: Warranty,
    newUser: boolean,
    forceRegistration: boolean
  ) {
    const response = await this.api.post<Warranty>(
      '/api/Warranties?newMachineUser=' + newUser,
      warranty,
      {
        params: {
          newMachineUser: newUser,
          forceRegistration: forceRegistration,
        },
      }
    )
    return response
  }

  public async getRegisteredWarranties(parameters: GetWarrantiesParameters) {
    const response = await this.api.post<GetWarrantiesResponse>(
      '/api/Warranties/GetList',
      parameters
    )
    return response
  }

  public async getInstructions(prefix: string) {
    const response = await this.api.get<InstructionBlob[]>(
      'api/ServiceInstructions/listWithPrefix?prefix=' + prefix
    )
    return response
  }

  public async getInstructionById(id: number) {
    const response = await this.api.get<InstructionFile>(
      'api/ServiceInstructions?instructionId=' + id
    )
    return response
  }

  public async getInstructionUploadLink(name: string) {
    const response = await this.api.get<string>(
      'api/ServiceInstructions/GetLinkToUpload?instructionName=' +
        encodeURIComponent(name)
    )
    return response
  }

  public async deleteInstruction(path: string) {
    const url = encodeURIComponent(path)
    const response = await this.api.delete(
      'api/ServiceInstructions?instructionName=' + url
    )
    return response
  }

  public async getMachineServiceRequests(machineId: number) {
    const response = await this.api.get<MachineServiceRequest[]>(
      '/api/ServiceRequests'
    )
    return response
  }
  public async editMachine(
    warrantyId: number | undefined,
    machine: EditMachine
  ) {
    if (warrantyId !== undefined) {
      const response = await this.api.put(
        'api/Warranties/EditMachine?id=' + warrantyId,
        machine
      )
      return response.data
    }
    return false
  }

  public async editMachineWithMachineId(
    machineId: number | undefined,
    machine: EditMachine
  ) {
    if (machineId !== undefined) {
      const response = await this.api.put(
        'api/Warranties/EditMachineWithMachineId?id=' + machineId,
        machine
      )
      return response.data
    }
    return false
  }

  public async editWarranty(
    warrantyId: number | undefined,
    warranty: Warranty,
    newUser: boolean
  ) {
    if (warrantyId !== undefined) {
      const response = await this.api.put(
        'api/Warranties?id=' + warrantyId + '&newMachineUser=' + newUser,
        warranty
      )
      return response.data
    }
    return false
  }

  public async getWarrantyPdfFile(warranty: Warranty, language: string) {
    const response = await this.api.post<Blob>(
      '/api/Warranties/PdfPreview?language=' + language,
      warranty,
      { responseType: 'blob' }
    )
    return response
  }

  public async getMachineDataWithUser(
    serialNumber: string,
    machineCode: string
  ) {
    const response = await this.api.post<GetMachineDataRespone | null>(
      '/api/ServiceRequest/GetMachineData',
      { serialNumber: serialNumber, machineCode: machineCode }
    )
    return response
  }

  public async createServiceRequest(
    serviceRequest: ServiceRequestBase,
    newMachineUser: boolean,
    machineFromErp?: boolean | null
  ) {
    let formData = new FormData()

    serviceRequest.files.forEach((item: any) => {
      formData.append('files', item.document, item.document.name)
    })
    formData.append('serviceRequest', JSON.stringify(serviceRequest))
    let url =
      `/api/ServiceRequest/` +
      serviceRequest.type +
      '?newMachineUser=' +
      newMachineUser
    if (machineFromErp !== undefined)
      url = url + '&machineFromErp=' + machineFromErp

    let options: AxiosRequestConfig = {
      url: url,
      method: 'post',
      data: formData,
    }

    const response = await this.api.request(options)
    return response
  }

  public async updateServiceRequest(serviceRequest: ServiceRequestFormValues) {
    return await this.api.post<ServiceRequestFormValues>(
      '/api/ServiceRequest/UpdateServiceRequest/',
      serviceRequest
    )
  }

  public async updateServiceRequestDocumentantion(
    serviceRequest: ServiceRequestFormValues
  ) {
    let formData = new FormData()

    serviceRequest.files.forEach((item: any) => {
      formData.append('files', item.document, item.document.name)
    })

    let options: AxiosRequestConfig = {
      url:
        `/api/ServiceRequest/UpdateServiceRequestDocumentantion?requestId=` +
        serviceRequest.id,
      method: 'post',
      data: formData,
    }

    const response = await this.api.request(options)
    return response
  }

  public async createReasonsRequest(
    reasonsRequest: ServiceRequestReasonFormValues
  ) {
    return await this.api.post<ServiceRequestReasonFormValues>(
      '/api/ServiceRequest/CreateReasonRequest/',
      reasonsRequest
    )
  }

  public async createCommentRequest(
    commentRequest: ServiceRequestCommentFormValues
  ) {
    let formData = new FormData()

    formData.append(
      'requestId',
      commentRequest.requestId ? commentRequest.requestId.toString() : ''
    )
    formData.append(
      'userId',
      commentRequest.userId ? commentRequest.userId.toString() : ''
    )
    formData.append('comment', commentRequest.comment)

    commentRequest.commentFiles.forEach((item: any) => {
      formData.append('commentFiles', item.document, item.document.name)
    })

    let options: AxiosRequestConfig = {
      url: '/api/ServiceRequest/CreateRequestComment?isSamaszComment=false',
      method: 'post',
      data: formData,
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    return await this.api.request(options)
  }

  public async createSamaszCommentRequest(
    commentRequest: ServiceRequestCommentFormValues
  ) {
    let formData = new FormData()

    formData.append(
      'requestId',
      commentRequest.requestId ? commentRequest.requestId.toString() : ''
    )
    formData.append(
      'userId',
      commentRequest.userId ? commentRequest.userId.toString() : ''
    )
    formData.append('comment', commentRequest.comment)

    let options: AxiosRequestConfig = {
      url: '/api/ServiceRequest/CreateRequestComment?isSamaszComment=true',
      method: 'post',
      data: formData,
    }

    return await this.api.request(options)
  }

  public async getServiceRequestPdfFile(payload: {
    id: number
    type: number
    language: string
  }) {
    const response = await this.api.post<Blob>(
      '/api/ServiceRequest/PdfPreview?type=' +
        payload.type +
        '&id=' +
        payload.id +
        '&language=' +
        payload.language,
      null,
      { responseType: 'blob' }
    )
    return response
  }

  public async getReport() {
    const response = await this.api.get<Report>('/api/Report')

    return response
  }

  public async getServiceRequests(parameters: GetServiceRequestsParameters) {
    const response = await this.api.post<GetServiceRequestsResponse>(
      '/api/ServiceRequest',
      {
        pageNumber: parameters.pageNumber,
        pageSize: parameters.pageSize,
        order: parameters.order,
        allTextSearch: parameters.allTextSearch,
        dealer: parameters.dealer,
        serialNumber: parameters.serialNumber,
        machineCode: parameters.machineCode,
        description: parameters.description,
        vehicleType: parameters.vehicleType,
        status: parameters.status,
        dateFrom: parameters.dateFrom,
        dateTo: parameters.dateTo,
        countries: parameters.countries,
        type: parameters.type,
      }
    )
    return response
  }

  public async getServiceRequestStatuses() {
    const response = await this.api.get<ServiceRequestStatus[]>(
      '/api/ServiceRequest/GetStatuses'
    )
    return response
  }

  public async getDescriptions() {
    const response = await this.api.get<string[]>(
      '/api/ServiceRequest/GetDescriptions'
    )
    return response
  }

  public async getVehicleTypes() {
    const response = await this.api.get<string[]>(
      '/api/ServiceRequest/GetVehicleTypes'
    )
    return response
  }

  public async getSelectedServiceRequest(id: number, type: number) {
    const response = await this.api.get<ServiceRequestBase>(
      'api/ServiceRequest/GetServiceRequest?id=' + id + '&type=' + type
    )
    return response
  }

  public async getPostalCodeData(code: string) {
    const response = await this.api.get<PostalCodeResponse[]>(
      'api/PostalCodeInfo?code=' + code
    )
    return response
  }

  public async verifyMachineUserAcronym(acronym: string) {
    const response = await this.api.get<boolean>(
      'api/MachineUser/VerifyAcronym?acronym=' + acronym
    )
    return response
  }

  public async verifyMachineUserExists(request: VerifyUserRequest) {
    const response = await this.api.post<VerifyUserResponse>(
      'api/MachineUser/VerifyUser',
      request
    )
    return response
  }

  public async changeServiceRequestStatus(
    changeStatusDto: ChangeServiceRequestStatus
  ) {
    const response = await this.api.put(
      'api/ServiceRequest/ChangeStatus',
      changeStatusDto
    )
    return response
  }

  public async getOrganizationsHavingRequests() {
    const response = await this.api.get(
      'api/ServiceRequest/GetOrganizationsHavingRequests'
    )
    return response
  }

  public async getServiceRequestSerialNumbers() {
    const response = await this.api.get<string[]>(
      'api/ServiceRequest/GetServiceRequestSerialNumbers'
    )
    return response
  }

  public async ChangeRequestToPaidRepair(requestId: number) {
    const response = await this.api.put(
      'api/ServiceRequest/ChangeRequestToPaidRepair?requestId=' + requestId
    )
    return response
  }

  public async checkIfUserHaveNewAlerts() {
    const response = await this.api.get<boolean>(
      'api/Alerts/CheckIfUserHaveNewAlerts'
    )
    return response
  }

  public async getAlerts() {
    const response = await this.api.get<Alert[]>('api/Alerts')
    return response
  }

  public async deleteAlerts(ids: number[]) {
    const response = await this.api.delete('api/Alerts/DeleteAlerts', {
      data: ids,
    })
    return response
  }

  public getMachineDetails = (id: number) => {
    return this.api.get<MachineDetails>('/api/Machine/GetById?id=' + id)
  }

  public getActualAnnouncements = () => {
    return this.api.get<AnnouncementDashboardItem[]>(
      '/api/Announcements/ActiveAnnouncemets'
    )
  }

  public getAnnouncementById = (id: number) => {
    return this.api.get<Announcement>('/api/Announcements/' + id)
  }

  public async createAnnouncement(announcement: AnnouncementFormValues) {
    return await this.api.post<AnnouncementFormValues>(
      '/api/Announcements/CreateAnnouncement',
      announcement
    )
  }

  public async deleteAnnouncement(id: number) {
    return await this.api.delete('/api/Announcements?id=' + id)
  }

  public async getAnnouncements(parameters: GetAnnouncementsParameters) {
    const response = await this.api.post<GetAnnouncementsResponse>(
      '/api/Announcements/GetAnnouncements',
      {
        pageNumber: parameters.pageNumber,
        pageSize: parameters.pageSize,
        order: parameters.order,
      }
    )
    return response
  }

  public editAnnouncement = (id: number, values: AnnouncementFormValues) => {
    return this.api.put('/api/Announcements?id=' + id, values)
  }
}

export default Api
