import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  DrawerProps as BaseDrawerProps,
} from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useAuth } from 'shared/hooks'
import { ModuleRoute } from 'shared/types'
import { TopbarFix } from '../Topbar'
import { StyledDrawer, useStyles } from './Drawer.style'

export interface DrawerProps extends Partial<BaseDrawerProps> {
  hidden?: boolean
  open: boolean
  onClose: (...args: any[]) => void
  navItems: ModuleRoute[]
}

const Drawer = ({ hidden, open, onClose, navItems, ...props }: DrawerProps) => {
  const { t } = useTranslation()
  const { tokenData } = useAuth()
  const classes = useStyles()
  return hidden ? null : (
    <StyledDrawer
      anchor="left"
      variant="permanent"
      color="secondary"
      open={open}
      hidden={false}
      {...props}
    >
      <TopbarFix>
        <IconButton id="closeDrawerBtn" edge="start" onClick={onClose}>
          <ChevronLeft />
        </IconButton>
      </TopbarFix>
      <Divider />
      <List disablePadding>
        {navItems.map(
          ({
            path,
            exact,
            needRole,
            needUserName,
            layoutSettings: { label, icon: Icon, needSpace } = {},
            ...other
          }) => {
            if (
              (needRole === undefined ||
                needRole.findIndex(role => role === tokenData?.role) !== -1) &&
              (!needUserName || needUserName === tokenData?.identifier)
            )
              return (
                <>
                  <ListItem
                    button
                    key={`item-${path}`}
                    id={`navitem-${path}`}
                    activeClassName="Mui-selected"
                    exact={exact}
                    to={path}
                    component={NavLink}
                    style={{ marginBottom: needSpace ? 10 : 'inherited' }}
                  >
                    {Icon && (
                      <ListItemIcon>
                        <Icon />
                      </ListItemIcon>
                    )}
                    <ListItemText
                      primary={t(label || '')}
                      primaryTypographyProps={{
                        color: 'inherit',
                        className: classes.listItem,
                      }}
                    />
                  </ListItem>
                </>
              )
          }
        )}
      </List>
    </StyledDrawer>
  )
}

export default Drawer
