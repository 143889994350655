import { createReducer } from '@reduxjs/toolkit'
import { AnnouncementDashboardItem, LoadingStatus } from 'shared/types'
import { getAnnouncements, resetPassword, resetState } from './actions'

interface State {
  loading: LoadingStatus
  error?: string | null
  announcements?: AnnouncementDashboardItem[]
}

const initialState: State = {
  loading: LoadingStatus.Idle,
  error: null,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(resetPassword.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(resetPassword.fulfilled, state => {
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(resetPassword.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.payload as string
    })
    .addCase(resetState, state => {
      state.loading = initialState.loading
      state.error = initialState.error
      state.announcements = undefined
    })
    .addCase(getAnnouncements.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
      state.announcements = undefined
    })
    .addCase(getAnnouncements.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.announcements = action.payload
      state.error = null
    })
    .addCase(getAnnouncements.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
      state.announcements = undefined
    })
)
