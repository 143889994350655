import { createReducer } from '@reduxjs/toolkit'
import { InstructionFile } from 'shared/services'
import { BlobListItem, LoadingStatus } from 'shared/types'
import {
  closeDirectory,
  getInstruction,
  getInstructions,
  getSubDirectories,
  openDirectory,
  resetLink,
} from './actions'

export interface State {
  loading: LoadingStatus
  error?: string | null
  instrucionsList: BlobListItem[]
  instructionArray: InstructionFile | null
}

const initialState: State = {
  loading: LoadingStatus.Idle,
  error: null,
  instrucionsList: [],
  instructionArray: null,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getInstructions.pending, (state, action) => {
      state.loading = LoadingStatus.Pending
      state.error = null
      state.instrucionsList = []
    })
    .addCase(getInstructions.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.error = null
      state.instrucionsList = action.payload.map(item => ({
        id: item.id,
        uniqueId: item.uniqueId,
        name: item.name,
        isDirectory: item.isDirectory,
        fileDirRef: item.fileDirRef,
        isOpen: false,
        subDirectories: [],
      }))
    })
    .addCase(getInstructions.rejected, (state, action) => {
      state.error = action.error.message
      state.loading = LoadingStatus.Failed
      state.instrucionsList = []
    })
    .addCase(getInstruction.pending, state => {
      state.error = null
      state.instructionArray = null
      state.loading = LoadingStatus.Pending
    })
    .addCase(getInstruction.fulfilled, (state, action) => {
      state.instructionArray = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getInstruction.rejected, (state, action) => {
      state.instructionArray = null
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getSubDirectories.pending, state => {
      state.error = null
      state.loading = LoadingStatus.Pending
    })
    .addCase(getSubDirectories.fulfilled, (state, action) => {
      state.error = null
      state.loading = LoadingStatus.Succeeded
      //Clicked folder name
      const prefix = action.payload.prefix
      //path to clicked folder
      //pathTree format: /sites/{site}/{Library}/{Folder1}/
      const pathTree = action.payload.path.split('/')

      const root = prefix
      //get root element of library
      const rootElem = state.instrucionsList.find(
        item => item.name === pathTree[4]
      )

      //if it's root element
      if (pathTree.length === 4) {
        state.instrucionsList = state.instrucionsList.map(item => {
          if (item.name !== root) return item
          else
            return {
              ...item,
              subDirectories: action.payload.response.map(item => ({
                id: item.id,
                uniqueId: item.uniqueId,
                name: item.name,
                isDirectory: item.isDirectory,
                fileDirRef: item.fileDirRef,
                isOpen: false,
                subDirectories: undefined,
              })),
              isOpen: true,
            }
        })
      } else {
        //It's any child folder
        let parent = root
        let parentEl = rootElem
        //if it's second child + n
        //find last element that subdirectory is our clicked folder
        if (pathTree.length > 5)
          for (let i = 5; i < pathTree.length; i++) {
            parent = pathTree[i]

            parentEl = parentEl?.subDirectories?.find(
              item => item.name === parent
            )
          }
        const directory = parentEl?.subDirectories?.find(
          item => item.name === root
        )
        //if directory found, fill it's subdirectory with returned file data
        if (directory !== undefined && parentEl?.subDirectories !== undefined) {
          directory.subDirectories = action.payload.response.map(item => ({
            id: item.id,
            uniqueId: item.uniqueId,
            name: item.name,
            isDirectory: item.isDirectory,
            fileDirRef: item.fileDirRef,
            isOpen: false,
            subDirectories: undefined,
          }))
          directory.isOpen = true
          //update parent element with directory data
          parentEl.subDirectories = parentEl.subDirectories.map(item => {
            if (item.name === directory.name) return { ...directory }
            else return item
          })
          //update instruction list state with new data
          state.instrucionsList = state.instrucionsList.map(item => {
            if (item.name !== root) return item
            else return { ...item }
          })
        }
      }
    })
    .addCase(getSubDirectories.rejected, (state, action) => {
      state.error = action.error.message
      state.loading = LoadingStatus.Failed
    })
    .addCase(resetLink, state => {
      state.instructionArray = null
    })
    .addCase(closeDirectory, (state, action) => {
      const pathTree = action.payload.fileDirRef.split('/')

      const root = action.payload.name
      const rootElem = state.instrucionsList.find(
        item => item.name === pathTree[4]
      )
      if (pathTree.length === 4) {
        state.instrucionsList = state.instrucionsList.map(item => {
          if (item.name !== root) return item
          else return { ...item, isOpen: false }
        })
      } else {
        let parent = root
        let parentEl = rootElem
        if (pathTree.length > 5)
          for (let i = 5; i < pathTree.length; i++) {
            parent = pathTree[i]

            parentEl = parentEl?.subDirectories?.find(
              item => item.name === parent
            )
          }
        const directory = parentEl?.subDirectories?.find(
          item => item.name === root
        )
        if (directory !== undefined && parentEl?.subDirectories !== undefined) {
          directory.isOpen = false
          parentEl.subDirectories = parentEl.subDirectories.map(item => {
            if (item.name === directory.name) return { ...directory }
            else return item
          })

          state.instrucionsList = state.instrucionsList.map(item => {
            if (item.name !== root) return item
            else return { ...item }
          })
        }
      }
    })
    .addCase(openDirectory, (state, action) => {
      const pathTree = action.payload.fileDirRef.split('/')

      const root = action.payload.name
      const rootElem = state.instrucionsList.find(
        item => item.name === pathTree[4]
      )
      if (pathTree.length === 4) {
        state.instrucionsList = state.instrucionsList.map(item => {
          if (item.name !== root) return item
          else return { ...item, isOpen: true }
        })
      } else {
        let parent = root
        let parentEl = rootElem
        if (pathTree.length > 5)
          for (let i = 5; i < pathTree.length; i++) {
            parent = pathTree[i]

            parentEl = parentEl?.subDirectories?.find(
              item => item.name === parent
            )
          }
        const directory = parentEl?.subDirectories?.find(
          item => item.name === root
        )
        if (directory !== undefined && parentEl?.subDirectories !== undefined) {
          directory.isOpen = true
          parentEl.subDirectories = parentEl.subDirectories.map(item => {
            if (item.name === directory.name) return { ...directory }
            else return item
          })

          state.instrucionsList = state.instrucionsList.map(item => {
            if (item.name !== root) return item
            else return { ...item }
          })
        }
      }
    })
)
