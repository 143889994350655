import { createSvgIcon } from '@material-ui/core'

export const ServiceIcon = createSvgIcon(
  <g>
    <path
      d="M17.3,5.7L14,2.4c-0.2-0.2-0.4-0.3-0.7-0.4v3.4c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.2,0.5,0.2,0.8,0.3h3
		V6.7v0C17.7,6.3,17.6,6,17.3,5.7z"
    />
    <path
      d="M12.7,5.4V2h-7C4.9,2,4.3,2.7,4.3,3.4v15.1c0,0.8,0.6,1.4,1.4,1.4h10.7c0,0,0,0,0,0c0.8,0,1.4-0.6,1.4-1.4V7.5l-3,0
		C13.6,7.5,12.7,6.5,12.7,5.4z M8,9.2C8,9.2,8,9.2,8,9.2C8,9.2,8,9.1,8,9.2L8,9.2C8.9,8.8,9.9,9,10.6,9.7c0.6,0.6,0.9,1.6,0.6,2.4
		l0,0.1l4.1,4.1c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-4.1-4.1l-0.1,0c-0.5,0.2-1.1,0.2-1.6,0c-0.6-0.2-1.1-0.7-1.3-1.3
		c-0.3-0.6-0.3-1.2,0-1.9c0,0,0.1,0,0.1,0l1.8,1.8L9.8,11L8,9.2z"
    />
  </g>,
  ''
)

export const ReportsIcon = createSvgIcon(
  <path d="M19 2h-4.18C14.4.84 13.3 0 12 0c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm7 18H5V4h2v3h10V4h2v16z" />,
  ''
)

export const OrganizationsIcon = createSvgIcon(
  <path d="M12,7V3H2v18h20V7H12z M10,19H4v-2h6V19z M10,15H4v-2h6V15z M10,11H4V9h6V11z M10,7H4V5h6V7z M20,19h-8V9h8V19z M18,11h-4v2 h4V11z M18,15h-4v2h4V15z" />,
  ''
)

export const ServiceRequestsIcon = createSvgIcon(
  <g>
    <path
      d="M17.7,5.1l-2.8-2.8c-0.2-0.2-0.3-0.3-0.6-0.3v2.9c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.7,0.2
		h2.5V5.9v0C18.1,5.6,18,5.3,17.7,5.1z"
    />
    <path
      d="M13.9,4.9V2H8C7.3,2,6.8,2.5,6.8,3.2v12.7c0,0.7,0.5,1.2,1.2,1.2h8.9c0,0,0,0,0,0c0.7,0,1.2-0.5,1.2-1.2V6.6l-2.5,0
		C14.6,6.6,13.8,5.8,13.9,4.9z M9.9,8C9.9,8,9.9,8,9.9,8C9.9,8,9.9,8,9.9,8L9.9,8c0.8-0.4,1.6-0.2,2.2,0.4c0.5,0.5,0.7,1.3,0.5,2
		l0,0.1l3.4,3.4c0.3,0.3,0.3,0.9,0,1.2c-0.3,0.3-0.9,0.3-1.2,0l-3.4-3.4l-0.1,0c-0.4,0.1-0.9,0.1-1.4,0c-0.5-0.2-0.9-0.6-1.1-1.1
		c-0.2-0.5-0.2-1,0-1.6c0,0,0.1,0,0.1,0l1.5,1.5l1-1L9.9,8z"
    />
    <path
      d="M15.1,17.7H7c-0.5,0-0.8-0.4-0.8-0.8V5c0-0.3-0.2-0.5-0.5-0.5S5.3,4.8,5.3,5v11.8c0,1,0.8,1.8,1.8,1.8h8.1
		c0.3,0,0.5-0.2,0.5-0.5C15.6,17.9,15.4,17.7,15.1,17.7z"
    />
    <path
      d="M12.3,19.1H5.7c-0.5,0-0.8-0.4-0.8-0.8V7.9c0-0.3-0.2-0.5-0.5-0.5S3.9,7.7,3.9,7.9v10.3c0,1,0.8,1.8,1.8,1.8h6.6
		c0.3,0,0.5-0.2,0.5-0.5C12.8,19.3,12.5,19.1,12.3,19.1z"
    />
  </g>,
  ''
)

export const MachineUsersIcon = createSvgIcon(
  <path d="M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z" />,
  ''
)

export const RegisteredWarrantiesIcon = createSvgIcon(
  <path d="M6 22v-16h16v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362zm18-7.614v-10.386h-20v20h10.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-5v-1h5v1zm5-4h-10v1h10v-1zm0-3h-10v1h10v-1zm3-6h-19v19h-1v-20h20v1zm-2-2h-19v19h-1v-20h20v1z" />,
  ''
)
