import { createReducer } from '@reduxjs/toolkit'
import {
  Announcement,
  AnnouncementsSortOrder,
  LoadingStatus,
  PaginationOptions,
} from 'shared/types'
import {
  createAnnouncement,
  deleteAnnouncement,
  editAnnouncement,
  getAnnouncementById,
  getAnnouncements,
  resetDeleted,
  resetState,
  setOrder,
  setPage,
} from './actions'

export interface State {
  loading: LoadingStatus
  error?: string | null
  paginationOptions: PaginationOptions
  order: AnnouncementsSortOrder
  announcements: Announcement[]
  created: boolean
  edited: boolean
  deleted: boolean
  editingAnnouncement?: Announcement
}

const initialState: State = {
  loading: LoadingStatus.Idle,
  error: null,
  paginationOptions: {
    totalPages: 0,
    current: 1,
    pageSize: 25,
  },
  order: AnnouncementsSortOrder.Default,
  announcements: [],
  created: false,
  edited: false,
  deleted: false,
  editingAnnouncement: undefined,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(createAnnouncement.pending, state => {
      state.loading = LoadingStatus.Pending
    })
    .addCase(createAnnouncement.fulfilled, state => {
      state.loading = LoadingStatus.Succeeded
      state.created = true
    })
    .addCase(createAnnouncement.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(editAnnouncement.pending, state => {
      state.loading = LoadingStatus.Pending
    })
    .addCase(editAnnouncement.fulfilled, state => {
      state.loading = LoadingStatus.Succeeded
      state.edited = true
    })
    .addCase(editAnnouncement.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getAnnouncements.pending, state => {
      state.loading = LoadingStatus.Pending
    })
    .addCase(getAnnouncements.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.announcements = action.payload.list
      state.paginationOptions = {
        ...state.paginationOptions,
        totalPages: action.payload.totalPages,
      }
    })
    .addCase(getAnnouncements.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(deleteAnnouncement.pending, state => {
      state.loading = LoadingStatus.Pending
    })
    .addCase(deleteAnnouncement.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.deleted = true
    })
    .addCase(deleteAnnouncement.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getAnnouncementById.pending, state => {
      state.loading = LoadingStatus.Pending
      state.editingAnnouncement = undefined
    })
    .addCase(getAnnouncementById.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.editingAnnouncement = action.payload
    })
    .addCase(getAnnouncementById.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
      state.editingAnnouncement = undefined
    })
    .addCase(setPage, (state, action) => {
      state.paginationOptions = {
        ...state.paginationOptions,
        current: action.payload,
      }
    })
    .addCase(setOrder, (state, action) => {
      state.order = action.payload
    })
    .addCase(resetDeleted, state => {
      state.deleted = false
    })
    .addCase(resetState, state => {
      return initialState
    })
)
