import { createAsyncThunk } from '@reduxjs/toolkit'
import { Api, LoginPayload } from 'shared/services'

const api = new Api()

export const login = createAsyncThunk(
  'auth/login',
  async (payload: LoginPayload, { rejectWithValue }) => {
    try {
      await api.login(payload)
    } catch (err) {
      if (!err.response) return rejectWithValue('networkError')

      if (err.response.data === 'account not activated')
        return rejectWithValue('notActivated')
      else if (err.response.data === 'acount locked')
        return rejectWithValue('locked')
      return rejectWithValue('loginError')
    }
  }
)
