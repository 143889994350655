import type { RootState } from 'app'

export const getOrganizationsWithChildren = (state: RootState) =>
  state.warranties.organizationsWithChildren

export const getOrganizations = (state: RootState) =>
  state.warranties.organizations

export const getCounties = (state: RootState) => state.warranties.counties

export const getVoivodeships = (state: RootState) =>
  state.warranties.voivodeships

export const getRegisteredWarrantiesList = (state: RootState) =>
  state.warranties.registeredWarrantiesList

export const isLoading = (state: RootState) => state.warranties.loading

export const isUserEditing = (state: RootState) =>
  state.warranties.isUserEditing

export const getError = (state: RootState) => state.warranties.error

export const getCountries = (state: RootState) => state.warranties.countries

export const getMachineUsers = (state: RootState) =>
  state.warranties.machineUsers

export const getVerifySerialNumber = (state: RootState) =>
  state.warranties.verifySerialNumber

export const getMachineData = (state: RootState) => state.warranties.machineData

export const getSelectedWarranty = (state: RootState) =>
  state.warranties.selectedWarranty

export const isMachineDetailsDialogOpen = (state: RootState) =>
  state.warranties.isMachineDetailsDialogOpen

export const getMachineServiceRequests = (state: RootState) =>
  state.warranties.machineServiceRequests

export const getIsWarrantyCreated = (state: RootState) =>
  state.warranties.isWarrantyCreated

export const getWarrantyPdfFile = (state: RootState) => state.warranties.pdfFile

export const getIsWarrantyEdit = (state: RootState) =>
  state.warranties.isWarrantyEdit

export const getPaginationOptions = (state: RootState) =>
  state.warranties.paginationOptions

export const getOrder = (state: RootState) => state.warranties.order

export const areFiltersVisible = (state: RootState) =>
  state.warranties.areFiltersVisible

export const getMachinesWithWarranties = (state: RootState) =>
  state.warranties.machinesWithWarranties

export const areMachinesFetcing = (state: RootState) =>
  state.warranties.machinesFetching

export const getFilterValues = (state: RootState) => state.warranties.filters

export const machineUserExists = (state: RootState) =>
  state.warranties.machineUserExists

export const editSaveBtnLock = (state: RootState) =>
  state.warranties.editSaveBtnLock
