import { Loadable } from 'shared/components'
import { MachineUsersIcon } from 'shared/Icons/Icons'
import { ModuleRoute, UserRoles } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: '/machieUsers',
    exact: true,
    layoutSettings: {
      icon: MachineUsersIcon,
      label: 'navigation.machineUsers',
      needSpace: true,
    },
    component: Loadable({
      component: () => import('./pages/MachineUsersList'),
    }),
  },
]

export default routes
