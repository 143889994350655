import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { Api } from 'shared/services'
import { UserFormValues } from '../components/UserForm/UserForm.utils'
import { UserStatus } from 'shared/types/enums'
import { UserPayload } from 'shared/services/Api/Api.types'
import { User } from 'shared/types/interfaces'
import { SetPasswordValues } from '../components/SetPasswordForm/SetPasswordForm.utils'

const api = new Api()

export const getUsers = createAsyncThunk('users/getUsers', async () => {
  const response = await api.getUsers()
  return response.data
})

export const getOrganizations = createAsyncThunk(
  'users/getOrganizations',
  async () => {
    const response = await api.getOrganizationsFaster()
    return response.data
  }
)
export const getCountries = createAsyncThunk('users/getCountries', async () => {
  const response = await api.getCountries()
  return response.data
})

export const getIdentifier = createAsyncThunk(
  'users/getIdentifier',
  async (payload: {
    surname: string
    organizationId: number
    user: User | null
    setValues: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  }) => {
    if (
      payload.surname === payload?.user?.surname &&
      payload.organizationId === payload?.user?.organizationId
    ) {
      payload.setValues('identifier', payload.user.identifier)
      return payload.user.identifier
    }
    const response = await api.getIdentifier(
      payload.surname,
      payload.organizationId
    )
    if (response.status === 200) payload.setValues('identifier', response.data)
    return response.data
  }
)

export const createUser = createAsyncThunk(
  'users/createUser',
  async (payload: UserFormValues) => {
    const user: UserPayload = {
      firstName: payload.name,
      surname: payload.surname,
      identifier: payload.identifier ?? '',
      email: payload.email,
      countryId: payload.country,
      organizationId: payload.organization,
      role: payload.role,
    }

    const response = await api.createUser(user)
    return response
  }
)

export const editUser = createAsyncThunk(
  'users/editUser',
  async (payload: { user: UserFormValues; id: number }) => {
    const user: UserPayload = {
      firstName: payload.user.name,
      surname: payload.user.surname,
      identifier: payload.user.identifier ?? '',
      email: payload.user.email,
      countryId: payload.user.country,
      organizationId: payload.user.organization,
      role: payload.user.role,
    }

    const response = await api.editUser(user, payload.id)
    return response
  }
)
export const editUserWithResendMail = createAsyncThunk(
  'users/editUserWithResendMail',
  async (payload: { user: UserFormValues; id: number }) => {
    const user: UserPayload = {
      firstName: payload.user.name,
      surname: payload.user.surname,
      identifier: payload.user.identifier ?? '',
      email: payload.user.email,
      countryId: payload.user.country,
      organizationId: payload.user.organization,
      role: payload.user.role,
    }

    const response = await api.editUserAndSendMail(user, payload.id)
    return response
  }
)

export const lockUser = createAsyncThunk(
  'users/lockUser',
  async (id: number) => {
    await api.changeUserStatus(id, UserStatus.Blocked)
    return id
  }
)

export const unLockUser = createAsyncThunk(
  'users/unLockUser',
  async (id: number) => {
    await api.changeUserStatus(id, UserStatus.Active)
    return id
  }
)

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (id: number) => {
    await api.changeUserStatus(id, UserStatus.Deleted)
    return id
  }
)

export const resetIdentifier = createAction<string>('users/resetIdentifier')

export const resetIsCreated = createAction('users/resetIsCreated')

export const resetIsEdited = createAction('users/resetIsEdited')

export const resetIsActivated = createAction('users/resetIsActivated')

export const activateUser = createAsyncThunk(
  'users/activate',
  async (
    payload: { token: string; values: SetPasswordValues },
    { rejectWithValue }
  ) => {
    const { token, values } = payload
    try {
      const response = await api.activateUser(token, values)
      return response
    } catch (err) {
      if (!err.response) return rejectWithValue('defaultError')
      return rejectWithValue(err.response.data)
    }
  }
)

export const changePassword = createAsyncThunk(
  'users/changePassword',
  async (
    payload: { token: string; values: SetPasswordValues },
    { rejectWithValue }
  ) => {
    const { token, values } = payload
    try {
      const response = await api.changePassword(token, values)
      return response
    } catch (err) {
      if (!err.response) return rejectWithValue('defaultError')
      return rejectWithValue(err.response.data)
    }
  }
)
