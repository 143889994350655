import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Api } from 'shared/services'

const api = new Api()

export const getAlerts = createAsyncThunk('alerts/get', async () => {
  const response = await api.getAlerts()
  return response.data
})

export const deleteAlerts = createAsyncThunk(
  'alerts/deleteAlerts',
  async (ids: number[]) => {
    const response = await api.deleteAlerts(ids)
    return response.data
  }
)

export const setSelectedAlertsId = createAction<number[]>(
  'alerts/setSelectedAlertsId'
)

export const resetState = createAction('alerts/resetState')

export const getCountries = createAsyncThunk(
  'alerts/getCountries',
  async () => {
    const response = await api.getCountries()
    return response.data
  }
)
