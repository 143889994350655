import { Dashboard } from '@material-ui/icons'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: '/login',
    exact: true,
    public: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/Login'),
    }),
  },
  {
    path: '/',
    exact: true,
    layoutSettings: {
      label: 'navigation.dashboard',
      icon: Dashboard,
      width100percents: true,
      needSpace: true,
    },
    component: Loadable({
      component: () => import('./pages/Dashboard'),
    }),
  },
  {
    path: '/resetPassword',
    exact: true,
    layoutSettings: {
      drawerProps: { hidden: true },
      topbarProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/PasswordReset'),
    }),
    public: true,
  },
  {
    path: '/accessDenied',
    exact: true,
    layoutSettings: {
      drawerProps: { hidden: true },
    },
    component: Loadable({
      component: () => import('./pages/AccessDenied'),
    }),
    public: true,
  },
]

export default routes
