import type { RootState } from 'app'

export const isLoading = (state: RootState) => state.announcements.loading

export const getError = (state: RootState) => state.announcements.error

export const getPaginationOptions = (state: RootState) =>
  state.announcements.paginationOptions

export const getOrder = (state: RootState) => state.announcements.order

export const getAnnouncements = (state: RootState) =>
  state.announcements.announcements

export const getIsCreated = (state: RootState) => state.announcements.created

export const getIsEdited = (state: RootState) => state.announcements.edited

export const getIsDeleted = (state: RootState) => state.announcements.deleted

export const getAnnouncement = (state: RootState) =>
  state.announcements.editingAnnouncement
