import type { RootState } from 'app'

export const isLoading = (state: RootState) =>
  state.catalogAndInstructions.loading

export const getError = (state: RootState) => state.catalogAndInstructions.error

export const getInstructions = (state: RootState) =>
  state.catalogAndInstructions.instrucionsList

export const getInstructionById = (state: RootState) =>
  state.catalogAndInstructions.instructionArray
