import { createReducer } from '@reduxjs/toolkit'
import {
  Country,
  LoadingStatus,
  MachineUser,
  Organization,
  VerifySerialNumberResponse,
  MachineDataResponse,
  Machine,
  ServiceRequestView,
  ServiceRequestStatus,
  PaginationOptions,
  ServiceRequestListFilters,
  ServiceRequestSortOrder,
  ServiceRequestBase,
  ServiceRequestDetailsScrollTo,
  Deliverers,
} from 'shared/types'
import {
  getCountries,
  getOrganizationWithChildren,
  getMachineUsersWithChildren,
  verifySerialNumber,
  getMachineData,
  getServiceRequestPdfFile,
  handleSelectedDealer,
  handleMachineUserIsDealer,
  resetState,
  createServiceRequest,
  updateServiceRequest,
  setSelectedColumns,
  getServiceRequests,
  getServiceRequestsStatuses,
  getDescriptions,
  getVehicleTypes,
  setPage,
  setPageSize,
  setOrder,
  setAllTextSearch,
  setDealer,
  setSerialNumber,
  setMachineCode,
  setDescription,
  setVehicleType,
  setStatus,
  setDateFrom,
  setDateTo,
  setType,
  getSelectedServiceRequest,
  openRequestReasonModal,
  closeRequestReasonModal,
  openCommentModal,
  closeCommentModal,
  resetGoToDetails,
  createReasonsRequest,
  createCommentRequest,
  insertSamaszComment,
  resetMachineData,
  getMachineBySerialNumberAndMachineCode,
  openBadSerialNumberModal,
  closeBadSerialNumberModal,
  setUserExists,
  getMachinesWithServiceRequests,
  goToDetails,
  scrollTo,
  getDeliverers,
  setIsIncompleteTypeModalOpen,
  changeStatus,
  setIsRejectTypeModalOpen,
  resetIsDetails,
  getOrganizationsHavingRequests,
  getServiceRequestSerialNumbers,
  resetMachine,
  setCountries,
  setFilters,
  setNeedFetchData,
  resetAllFilters,
  reloadDetails,
  resetAllState,
  changeRequestToPaidRepair,
  setIsCorrecting,
  openGallery,
  closeGallery,
  resetFormCorrecting,
  toggleFiltersVisibility,
  getEndWarranty,
} from './actions'
import { Columns } from '../components/Filters/Filters.utils'

interface State {
  loading: LoadingStatus
  error?: string | null
  organizationsWithChildren: Organization[]
  countries: Country[]
  machineUsers: MachineUser[] | undefined
  verifySerialNumber: VerifySerialNumberResponse | null
  machineData: MachineDataResponse[] | null
  machine: Machine | undefined | null
  saleDate: string | undefined
  endWarranty: string | undefined
  machineExistsInErp: boolean | undefined
  pdfFile: Blob | null
  selectedDealer: Organization | null
  machineUserIsDealer: boolean
  isCreated: boolean
  selectedColumns: Columns[]
  serviceRequestList: ServiceRequestView[]
  machinesOnlyWithRequests: Machine[]
  statuses: ServiceRequestStatus[]
  vehicleTypes: string[]
  descriptions: string[]
  deliverers: Deliverers[]
  paginationOptions: PaginationOptions
  filters: ServiceRequestListFilters
  areFiltersVisible: boolean
  order: ServiceRequestSortOrder
  isReasonsAdded: boolean
  isCommentAdded: boolean
  selectedServiceRequest: ServiceRequestBase | null
  selectedRequestId?: number
  selectedRequestType?: number
  isServiceRequestDetails: boolean
  isServiceRequestAffected: boolean
  isRequestReasonModalOpen: boolean
  isRequestCommentModalOpen: boolean
  goToDetails: boolean
  badSerialNumberModalOpen: boolean
  machineUserExists: boolean
  serviceRequestDetailsScrollTo: ServiceRequestDetailsScrollTo
  isIncompleteTypeModalOpen: boolean
  isRejectTypeModalOpen: boolean
  organizationsToFilter: Organization[]
  organizationsFetching: boolean
  serialNumbers: string[]
  serialNumbersFetching: boolean
  needFetchCount: number
  needReload: boolean
  isRequestCorrecting: boolean
  isGalleryOpen: boolean
  machineUsersFetchig: boolean
}

const initialState: State = {
  loading: LoadingStatus.Idle,
  error: null,
  organizationsWithChildren: [],
  countries: [],
  machineUsers: undefined,
  verifySerialNumber: null,
  machine: undefined,
  saleDate: undefined,
  endWarranty: undefined,
  machineExistsInErp: undefined,
  machineData: null,
  pdfFile: null,
  selectedDealer: null,
  machineUserIsDealer: false,
  isCreated: false,
  selectedColumns: [],
  serviceRequestList: [],
  machinesOnlyWithRequests: [],
  statuses: [],
  vehicleTypes: [],
  deliverers: [],
  descriptions: [],
  paginationOptions: {
    totalPages: 0,
    current: 1,
    pageSize: 25,
  },
  order: ServiceRequestSortOrder.FailureDateDESC,
  filters: {
    allTextSearch: '',
    dealer: [],
    serialNumber: [],
    machineCode: [],
    description: [],
    vehicleType: [],
    status: [],
    dateFrom: '',
    dateTo: '',
    countries: [],
    type: [],
  },
  areFiltersVisible: true,
  isReasonsAdded: false,
  isCommentAdded: false,
  selectedServiceRequest: null,
  isServiceRequestDetails: true,
  isRequestReasonModalOpen: false,
  isRequestCommentModalOpen: false,
  isServiceRequestAffected: false,
  goToDetails: false,
  badSerialNumberModalOpen: false,
  machineUserExists: false,
  serviceRequestDetailsScrollTo: ServiceRequestDetailsScrollTo.NoScroll,
  isIncompleteTypeModalOpen: false,
  isRejectTypeModalOpen: false,
  organizationsToFilter: [],
  organizationsFetching: false,
  serialNumbers: [],
  serialNumbersFetching: false,
  needFetchCount: 0,
  needReload: false,
  isRequestCorrecting: false,
  isGalleryOpen: false,
  machineUsersFetchig: true,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(getOrganizationWithChildren.pending, state => {
      state.organizationsWithChildren = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getOrganizationWithChildren.fulfilled, (state, action) => {
      state.organizationsWithChildren = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getOrganizationWithChildren.rejected, (state, action) => {
      state.organizationsWithChildren = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })

    .addCase(getCountries.pending, state => {
      state.countries = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getCountries.rejected, (state, action) => {
      state.countries = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getMachineUsersWithChildren.pending, state => {
      state.machineUsers = undefined
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
      state.machineUsersFetchig = true
    })
    .addCase(getMachineUsersWithChildren.fulfilled, (state, action) => {
      state.machineUsers = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
      state.machineUsersFetchig = false
    })
    .addCase(getMachineUsersWithChildren.rejected, (state, action) => {
      state.machineUsers = undefined
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(verifySerialNumber.pending, state => {
      state.verifySerialNumber = null
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(verifySerialNumber.fulfilled, (state, action) => {
      state.verifySerialNumber = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(verifySerialNumber.rejected, (state, action) => {
      state.verifySerialNumber = null
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getEndWarranty.pending, (state, action) => {
      state.endWarranty = undefined
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getEndWarranty.fulfilled, (state, action) => {
      state.endWarranty = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getEndWarranty.rejected, (state, action) => {
      state.endWarranty = undefined
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getMachineData.pending, state => {
      state.machineData = null
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getMachineData.fulfilled, (state, action) => {
      state.machineData = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getMachineData.rejected, (state, action) => {
      state.machineData = null
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getMachineBySerialNumberAndMachineCode.pending, state => {
      state.machine = undefined
      state.saleDate = undefined
      state.endWarranty = undefined
      state.machineExistsInErp = undefined
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(
      getMachineBySerialNumberAndMachineCode.fulfilled,
      (state, action) => {
        state.machine = action.payload?.machine || null
        state.saleDate = action.payload?.saleDate
        state.endWarranty = action.payload?.endWarranty
        state.machineExistsInErp = action.payload?.existsInErp
        state.loading = LoadingStatus.Succeeded
        state.error = null
      }
    )
    .addCase(
      getMachineBySerialNumberAndMachineCode.rejected,
      (state, action) => {
        state.machine = null
        state.saleDate = undefined
        state.endWarranty = undefined
        state.machineExistsInErp = undefined
        state.loading = LoadingStatus.Failed
        state.error = action.error.message
      }
    )

    .addCase(getServiceRequestPdfFile.pending, state => {
      state.pdfFile = null
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(getServiceRequestPdfFile.fulfilled, (state, action) => {
      state.pdfFile = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getServiceRequestPdfFile.rejected, (state, action) => {
      state.pdfFile = null
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(handleSelectedDealer, (state, action) => {
      state.selectedDealer = action.payload
    })
    .addCase(handleMachineUserIsDealer, (state, action) => {
      state.machineUserIsDealer = action.payload
    })
    .addCase(resetState, (state, action) => {
      state.isCreated = false
      state.isServiceRequestDetails = false
    })
    .addCase(createServiceRequest.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(createServiceRequest.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.error = null
      state.isCreated = true
      state.pdfFile = null
    })
    .addCase(createServiceRequest.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(updateServiceRequest.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(updateServiceRequest.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.error = null
      state.isServiceRequestAffected = true
      state.isServiceRequestDetails = true
    })
    .addCase(updateServiceRequest.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
      state.isServiceRequestAffected = false
      state.isServiceRequestDetails = false
    })
    .addCase(setSelectedColumns, (state, action) => {
      state.selectedColumns = action.payload
    })
    .addCase(getServiceRequests.pending, state => {
      state.serviceRequestList = []
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
      state.needFetchCount = state.needFetchCount - 1
    })
    .addCase(getServiceRequests.fulfilled, (state, action) => {
      state.serviceRequestList = action.payload.list
      state.paginationOptions = {
        ...state.paginationOptions,
        totalPages: action.payload.totalPages,
      }
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(getServiceRequests.rejected, (state, action) => {
      state.serviceRequestList = []
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(getMachinesWithServiceRequests.pending, state => {
      state.machinesOnlyWithRequests = []
      state.error = null
    })
    .addCase(getMachinesWithServiceRequests.fulfilled, (state, action) => {
      state.machinesOnlyWithRequests = action.payload
      state.error = null
    })
    .addCase(getMachinesWithServiceRequests.rejected, (state, action) => {
      state.error = action.error.message
    })
    .addCase(getServiceRequestsStatuses.pending, state => {
      state.statuses = []
      state.error = null
    })
    .addCase(getServiceRequestsStatuses.fulfilled, (state, action) => {
      state.statuses = action.payload
      state.error = null
    })
    .addCase(getServiceRequestsStatuses.rejected, (state, action) => {
      state.error = action.error.message
    })
    .addCase(getDeliverers.pending, state => {
      state.deliverers = []
      state.error = null
    })
    .addCase(getDeliverers.fulfilled, (state, action) => {
      state.deliverers = action.payload
      state.error = null
    })
    .addCase(getDeliverers.rejected, (state, action) => {
      state.error = action.error.message
    })
    .addCase(getVehicleTypes.pending, state => {
      state.vehicleTypes = []
      state.error = null
    })
    .addCase(getVehicleTypes.fulfilled, (state, action) => {
      state.vehicleTypes = action.payload
      state.error = null
    })
    .addCase(getVehicleTypes.rejected, (state, action) => {
      state.error = action.error.message
    })
    .addCase(getDescriptions.pending, state => {
      state.descriptions = []
      state.error = null
    })
    .addCase(getDescriptions.fulfilled, (state, action) => {
      state.descriptions = action.payload
      state.error = null
    })
    .addCase(getDescriptions.rejected, (state, action) => {
      state.error = action.error.message
    })
    .addCase(setPage, (state, action) => {
      state.paginationOptions = {
        ...state.paginationOptions,
        current: action.payload,
      }
    })
    .addCase(setPageSize, (state, action) => {
      state.paginationOptions = {
        ...state.paginationOptions,
        pageSize: action.payload,
        current: 1,
      }
    })
    .addCase(setOrder, (state, action) => {
      state.order = action.payload
      state.needFetchCount = state.needFetchCount + 1
    })
    .addCase(setAllTextSearch, (state, action) => {
      state.filters = { ...state.filters, allTextSearch: action.payload }
    })
    .addCase(setDealer, (state, action) => {
      state.filters = { ...state.filters, dealer: action.payload }
    })
    .addCase(setSerialNumber, (state, action) => {
      state.filters = { ...state.filters, serialNumber: action.payload }
    })
    .addCase(setMachineCode, (state, action) => {
      state.filters = { ...state.filters, machineCode: action.payload }
    })
    .addCase(setDescription, (state, action) => {
      state.filters = { ...state.filters, description: action.payload }
    })
    .addCase(setVehicleType, (state, action) => {
      state.filters = { ...state.filters, vehicleType: action.payload }
    })
    .addCase(setStatus, (state, action) => {
      state.filters = { ...state.filters, status: action.payload }
    })
    .addCase(setType, (state, action) => {
      state.filters = { ...state.filters, type: action.payload }
    })
    .addCase(setDateFrom, (state, action) => {
      state.filters = { ...state.filters, dateFrom: action.payload }
    })
    .addCase(setDateTo, (state, action) => {
      state.filters = { ...state.filters, dateTo: action.payload }
    })
    .addCase(setCountries, (state, action) => {
      state.filters = { ...state.filters, countries: action.payload }
    })
    .addCase(setFilters, (state, action) => {
      state.filters = {
        ...state.filters,
        allTextSearch: action.payload.allTextSearch,
        dealer: action.payload.dealer,
        serialNumber: action.payload.serialNumber,
        machineCode: action.payload.machineCode,
        description: action.payload.description,
        vehicleType: action.payload.vehicleType,
        status: action.payload.status,
        dateFrom: action.payload.dateFrom,
        dateTo: action.payload.dateTo,
        countries: action.payload.countries,
        type: action.payload.type,
      }
    })
    .addCase(getSelectedServiceRequest.fulfilled, (state, action) => {
      state.selectedServiceRequest = action.payload.request
      state.selectedRequestType = action.payload.type
      state.selectedRequestId = action.payload.id
      state.loading = LoadingStatus.Succeeded
      state.isServiceRequestAffected = false
    })
    .addCase(getSelectedServiceRequest.pending, (state, action) => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
      state.selectedServiceRequest = null
      state.needReload = false
    })
    .addCase(getSelectedServiceRequest.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
      state.selectedServiceRequest = null
      state.isServiceRequestAffected = false
    })
    .addCase(goToDetails, (state, action) => {
      state.selectedRequestId = action.payload.id
      state.selectedRequestType = action.payload.serviceRequestType
      state.isServiceRequestDetails =
        action.payload.isCorrecting === undefined ? true : !action.payload.isCorrecting
      state.goToDetails = true
    })
    .addCase(openRequestReasonModal, state => {
      state.isRequestReasonModalOpen = true
    })
    .addCase(closeRequestReasonModal, state => {
      state.isRequestReasonModalOpen = false
    })
    .addCase(openCommentModal, state => {
      state.isRequestCommentModalOpen = true
    })
    .addCase(closeCommentModal, state => {
      state.isRequestCommentModalOpen = false
    })
    .addCase(resetGoToDetails, state => {
      state.goToDetails = false
    })
    .addCase(resetIsDetails, state => {
      state.isServiceRequestDetails = false
    })
    .addCase(createCommentRequest.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(createCommentRequest.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(createCommentRequest.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(insertSamaszComment.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(insertSamaszComment.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.error = null
      state.isServiceRequestAffected = true
    })
    .addCase(insertSamaszComment.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
      state.isServiceRequestAffected = false
    })
    .addCase(createReasonsRequest.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
      state.isReasonsAdded = false
    })
    .addCase(createReasonsRequest.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.error = null
      //@ts-ignore
      state.selectedServiceRequest =
        state.selectedServiceRequest != null
          ? {
              ...state.selectedServiceRequest,
              reason: {
                reason1: action.payload.reason1 || '',
                reason2: action.payload.reason2 || '',
                reason3: action.payload.reason3 || '',
                id:
                  action.payload.reasonId === null
                    ? 0
                    : action.payload.reasonId,
              },
            }
          : null
      state.isReasonsAdded = true
    })
    .addCase(createReasonsRequest.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
      state.isReasonsAdded = false
    })
    .addCase(resetMachineData, state => {
      state.machineData = null
    })
    .addCase(openBadSerialNumberModal, state => {
      state.badSerialNumberModalOpen = true
    })
    .addCase(closeBadSerialNumberModal, state => {
      state.badSerialNumberModalOpen = false
    })
    .addCase(setUserExists, (state, action) => {
      state.machineUserExists = action.payload
    })
    .addCase(scrollTo, (state, action) => {
      state.serviceRequestDetailsScrollTo = action.payload
    })
    .addCase(setIsIncompleteTypeModalOpen, (state, action) => {
      state.isIncompleteTypeModalOpen = action.payload
    })
    .addCase(setIsRejectTypeModalOpen, (state, action) => {
      state.isRejectTypeModalOpen = action.payload
    })
    .addCase(changeStatus.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(changeStatus.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(changeStatus.fulfilled, state => {
      state.loading = LoadingStatus.Succeeded
    })
    .addCase(changeRequestToPaidRepair.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(changeRequestToPaidRepair.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(changeRequestToPaidRepair.fulfilled, state => {
      state.loading = LoadingStatus.Succeeded
    })
    .addCase(getOrganizationsHavingRequests.pending, state => {
      state.organizationsFetching = true
      state.error = initialState.error
    })
    .addCase(getOrganizationsHavingRequests.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
      state.organizationsFetching = false
    })
    .addCase(getOrganizationsHavingRequests.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.organizationsToFilter = action.payload
      state.organizationsFetching = false
    })
    .addCase(getServiceRequestSerialNumbers.pending, state => {
      state.serialNumbersFetching = true
      state.error = initialState.error
    })
    .addCase(getServiceRequestSerialNumbers.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
      state.serialNumbersFetching = false
    })
    .addCase(getServiceRequestSerialNumbers.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.serialNumbers = action.payload.filter(number => number)
      state.serialNumbersFetching = false
    })
    .addCase(resetMachine, state => {
      state.machine = undefined
      state.saleDate = undefined
      state.endWarranty = undefined
      state.machineExistsInErp = undefined
    })
    .addCase(setNeedFetchData, state => {
      state.needFetchCount = state.needFetchCount + 1
    })
    .addCase(resetAllFilters, state => {
      state.filters = initialState.filters
      state.order = initialState.order
      state.paginationOptions = initialState.paginationOptions
      state.needFetchCount = initialState.needFetchCount
    })
    .addCase(reloadDetails, state => {
      state.needReload = true
    })
    .addCase(resetAllState, state => {
      return initialState
    })
    .addCase(setIsCorrecting, (state, action) => {
      state.isRequestCorrecting = action.payload
    })
    .addCase(openGallery, state => {
      state.isGalleryOpen = true
    })
    .addCase(closeGallery, state => {
      state.isGalleryOpen = false
    })
    .addCase(resetFormCorrecting, state => {
      state.isRequestCorrecting = false
      state.isServiceRequestDetails = true
    })
    .addCase(toggleFiltersVisibility, state => {
      state.areFiltersVisible = !state.areFiltersVisible
    })
)
