import {
  styled,
  AppBar as BaseAppBar,
  Theme,
  makeStyles,
} from '@material-ui/core'
import { OPEN_DRAWER_WIDTH } from '../Drawer'

export const TOOLBAR_HEIGHT = 73

interface AppBarProps {
  shifted: boolean
}

export const AppBar = styled(BaseAppBar)<Theme, AppBarProps>(
  ({ theme, shifted }) => ({
    zIndex: theme.zIndex.drawer + 1,
    ...(shifted && {
      marginLeft: OPEN_DRAWER_WIDTH,
      width: `calc(100% - ${OPEN_DRAWER_WIDTH}px)`,
    }),
    '& .MuiToolbar-root': {
      minHeight: TOOLBAR_HEIGHT,
    },
  })
)

export const TopbarFix = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
  minHeight: TOOLBAR_HEIGHT,
}))

export const ToolbarButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  //flex: 1,
  justifyContent: 'flex-end',
  alignItems: 'center',
}))

export const useStyles = makeStyles(theme => ({
  userBox: {
    fontSize: 16,
    fontFamily: 'HKGrotesk',
    fontWeight: 500,
    color: theme.palette.secondary.dark,
  },
  select: {
    color: theme.palette.secondary.dark,
    fontSize: 12,
    fontWeight: 600,
    fontFamily: 'SinkinSans',
    border: 'none',
    backgroundColor: 'inherit',
  },
}))
