export const MODULE_NAME = 'dashboard'

export const paths = {
  warrantyRegistration: '/register-warranty',
  organizations: '/organizations',
  addOrganization: '/addOrganization',
  editOrganization: '/editOrganization', 
  addServiceRequest: '/addServiceRequest',

}
